@import "../variables";
//@import "../mixins";
//@import "../mq";

.Search-bar {
  &__wrapper,
  &__input {
    position: relative;
  }

  &__hint {
    position: absolute;
  }

  &__menu {
    background-color: $white;
    border: 1px solid $border-color;
    border-top: 0;
    border-radius: $border-radius/2;
    max-height: 342px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  &__menu--visible {
    display: block;
  }

  &__menu--hidden {
    display: none;
  }

  &__option {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    font-size: 0.81818rem;
    justify-content: space-between;
    padding: 12px;
    position: relative;

    span:nth-of-type(2) {
      color: $mid-grey;
      white-space: nowrap;
    }

    > * {
      pointer-events: none;
    }

    &--focused {
      background-color: $color-default;
      color: $white;
      outline: 0;
    }

    &--no-results {
      background-color: $white;
      color: $mid-grey;
      cursor: not-allowed;
    }
  }

  @media (hover: hover) {
    &__option:hover {
      background-color: $color-default;
      color: $white;
      outline: 0;
    }
  }
}

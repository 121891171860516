@import "../../variables";
@import "../../mixins";
@import "../../mq";

.tabbed-content-panel {
  @extend %panel;

  padding-bottom: 0;

  @include mq($until: M) {
    padding-top: 0;
  }

  &__heading {
    color: inherit;
    margin-bottom: $space * 2;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq($until: M) {
      & + & {
        margin-top: $space * 2;
      }
    }

    .panel--valign-top & {
      justify-content: flex-start;
    }

    .panel--valign-bottom & {
      justify-content: flex-end;
    }

    .tabbed-content-panel__tab--two-column & {
      @include mq($from: M) {
        margin-right: 2%;
        width: 49%;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__tab-list {
    display: flex;
    align-items: center;
    margin: 0 -5vw;
    padding: 0 5vw;
    position: relative;
    overflow-x: auto;
    z-index: 2;
    background: var(--theme-color);
    color: var(--theme-color-contrast);
    height: 80px;

    .panel--inverted:not(.panel--theme-none) & {
      background: var(--theme-color-contrast, $color-default);
      color: var(--theme-color);
    }

    .panel--inverted.panel--theme-none & {
      background: $color-default;
      color: $white;
    }

    @include mq($from: M) {
      margin: 0 0 (-80px / 2);
      padding: 0 $space * 1.5;
    }
  }

  &__tab-list-item {
    cursor: pointer;
    margin-right: $space * 1.5;
    padding: $space/2 0;
    position: relative;
    color: inherit;
    font-size: $minFont;
    font-weight: normal;
    white-space: nowrap;

    &::after {
      content: "";
      display: block;
      width: 100;
      height: 4px;
      background: currentColor;
      transform: skew($default-skew * 2) scaleX(0);
      transition: transform $transition-speed $transition-timing;
      transform-origin: 0 50%;
    }

    &.active::after {
      transform: skew($default-skew * 2) scaleX(1);
    }
  }

  &__tabs {
    @extend %panel;

    position: relative;
    color: $default-text-color;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 100%;
      transform: translateX(-50%);
      z-index: -1;
    }

    .panel--inverted & {
      color: var(--theme-color-contrast);

      &::before {
        background-color: var(--theme-color);
      }
    }

    .panel--dark & {
      color: $white;

      &::before {
        background-color: $color-default;
      }
    }
  }

  &__tab {
    display: none;

    &.active {
      display: block;
    }

    &--two-column {
      flex-direction: column;

      &.active {
        display: flex;
      }

      @include mq($from: M) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
}

@import "../variables";
// @import "../mixins";
// @import "../mq";

.breadcrumbs {
  border-bottom: 1px solid $border-color;
  padding: $space 0;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9rem;
    list-style: none;
    margin-bottom: 0;
  }

  &__crumb {
    &::after {
      content: ">";
      opacity: 0.5;
      padding: 0 0.5rem;
    }

    &:last-child {
      font-weight: 700;

      &::after {
        content: none;
      }
    }
  }
}

@import "../../variables";
@import "../../mixins";
@import "../../mq";

.content-panel {
  @extend %panel;

  &__heading {
    color: inherit;
    margin-bottom: 1rem;
    -webkit-text-fill-color: unset;
    -webkit-text-stroke-width: 0;
    text-align: center;

    @include mq($from: M) {
      margin-bottom: $space * 2;
    }

    &:only-child {
      margin-bottom: 0;
    }

    .panel--inverted & {
      color: var(--theme-color-contrast);
    }

    .panel--dark & {
      color: $white;
    }

    .panel--solid-heading & {
      -webkit-text-fill-color: unset !important;
      -webkit-text-stroke-width: 0;
    }
  }

  &__row {
    &--two-column {
      display: flex;
      flex-direction: column-reverse;

      .panel:nth-child(odd) & {
        flex-direction: column;
      }

      @include mq($from: M) {
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -2%;

        .panel:nth-child(odd) & {
          flex-direction: row;
        }
      }

      .content-panel__column {
        @include mq($from: M) {
          margin: 0 2%;
          width: 46%;
        }
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    -ms-flex-negative: 0;

    > div {
      -ms-flex-negative: 0;
    }

    @include mq($until: L) {
      padding-bottom: $baseline/2;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .panel--valign-top & {
      justify-content: flex-start;
    }

    .panel--valign-bottom & {
      justify-content: flex-end;
    }

    .panel--layout-wide-left & {
      @include mq($from: M) {
        width: 62.6666%;

        &:last-child {
          width: 29.3333%;
        }
      }
    }

    .panel--layout-wide-right & {
      @include mq($from: M) {
        width: 29.3333%;

        &:last-child {
          width: 62.6666%;
        }
      }
    }

    .panel--layout-extra-wide-left & {
      @include mq($from: M) {
        width: 71%;

        &:last-child {
          width: 21%;
        }
      }
    }

    .panel--layout-extra-wide-right & {
      @include mq($from: M) {
        width: 21%;

        &:last-child {
          width: 71%;
        }
      }
    }
  }
}

// Spacing
$space: 20px;
$content-width: 90%; // Page width
$max-content-width: 1200px; // Maximum width before it just ends up centered (Containers will extend by default)

// Border radius
$border-radius: 4px;

// Colours (Brand)
$color-default: #2e2840;
$color-big-bang: #f70059;
$color-competition: #fec700;
$color-digital: #c114c6;
$color-at-school: #14ed99;
$color-fair: #3df9f4;

// Colours (Base)
$black: #000000;
$white: #ffffff;
$dark-grey: #333333;
$grey: #a4a4a4;
$mid-grey: #828282;
$keyline: #d9d9d9;
$lightest-grey: #f7f7f7;
$red: $color-default;

// Colours (Misc)
$body-background-color: $white;
$default-text-color: $dark-grey;
$primary-highlight-color: $color-default;
$secondary-highlight-color: $color-default;
$default-link-color: $color-default;
$hover-link-color: darken($color-default, 5%);
$error: $color-big-bang;
$border-color: $keyline;
$form-border-color: $keyline;
$form-label-color: $default-text-color;
$form-background-color: $white;
$form-label-color-darktheme: $white;
$form-label-color-medtheme: $white;
$form-tooltip-color: $default-link-color;
$form-placeholder-color: $grey;
$button-primary-color: $red;
$heading-color: $color-default;
$form-focus-color: $default-link-color;
$quote-text-color: var(--theme-color);
$quote-cite-color: $color-default;
$footer-background: darken($color-default, 5%);
$footer-text-color: $white;

// Modifiers
$box-shadow: 0 5px 15px rgba($black, 0.15);
$default-skew: -8deg;
$text-stroke-width: 1px;

// Fonts
$base-font: "Poppins", sans-serif;
$heading-font: "railroad-gothic-atf", sans-serif;

$baseline: 1.5rem;

$minScreen: 20rem;
$maxScreen: 90rem;
$minFont: 1rem;
$maxFont: 1.125rem;

$h1-lg: 4rem; // 64px
$h1-sm: 3rem; // 48px
$h2-lg: 3.75rem; // 60px
$h2-sm: 2.1875rem; // 35px
$h3-lg: 2.5rem; // 40px
$h3-sm: 2rem; // 30px
$h4-lg: 1.75rem; // 30px
$h4-sm: 1.5625rem; // 25px
$h5-lg: 1.25rem; // 20px
$h5-sm: 1rem; // 16px

//// Media Queries
// Uncomment this if your site isn't responsive. Enjoy your trip to the past!
// $mq-responsive: false;

// Responsive breakpoints, use semantic names
$mq-breakpoints: (
  XS: 320px,
  S: 480px,
  M: 720px,
  L: 980px,
  XL: 1024px,
  XXL: 1280px
);

// Define the breakpoint from the $mq-breakpoints list that IE8 should get
$mq-static-breakpoint: XL;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: () !default;

// Animation Variables
$transition-speed: 0.15s;
$transition-timing: ease-in-out;

$deep-blue: #191e38;
$brand-tertiary: #93c90e; // Green
$transition-speed: 0.15s;
$transition-timing: ease-in-out;

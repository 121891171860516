@import "../variables";
//@import "../mixins";
@import "../mq";

.article,
.article-list,
.article-feed-panel {
  .grid__heading {
    margin-bottom: $baseline/4;

    a {
      transition: color $transition-speed $transition-timing;
    }
  }

  &__heading {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $baseline/2;

    @include mq($from: M) {
      a {
        display: block;
        margin-left: auto;
        margin-right: -$space;
      }
    }

    span {
      margin-right: $space * 2;
    }

    a {
      margin-top: 18px;
      width: auto;
    }
  }

  &__date,
  .grid__date {
    color: $primary-highlight-color;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__date {
    color: $default-text-color;
  }

  &__categories,
  .grid__categories {
    align-items: flex-start;
    color: $primary-highlight-color;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 $baseline/2;

    li {
      background-color: rgba($primary-highlight-color, 0.2);
      font-weight: bold;
      font-size: 0.6667rem;
      line-height: 1;
      margin-bottom: 5px;
      margin-right: 5px;
      padding: 5px;
      text-transform: uppercase;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__categories {
    margin-bottom: 0;
    margin-left: auto;
    padding-bottom: $space/4;
  }
}

.article,
.article-feed-panel {
  padding: $space 0;

  @include mq($from: M) {
    padding: $space * 2 0;
  }

  &__featured {
    padding-bottom: $space;

    @include mq($from: M) {
      padding-bottom: $space * 2;
    }

    @include mq($from: L) {
      padding-bottom: $space * 4;
    }

    h2 {
      text-align: center;
    }
  }

  &__meta,
  &__actions {
    font-size: 0.75rem;

    @include mq($from: S) {
      align-items: center;
      display: flex;
      text-transform: uppercase;
    }

    &:first-child .article__share {
      @include mq($until: S) {
        display: none;
      }
    }
  }

  &__meta {
    border-bottom: 1px solid $border-color;
    padding-bottom: $space;
  }

  &__actions {
    border-top: 1px solid $border-color;
    padding-top: $space;
  }

  &__back {
    display: inline-block;
    text-decoration: none;

    @include mq($until: M) {
      padding-bottom: $baseline;
    }

    .icon {
      fill: $default-link-color;
      height: 0.5rem;
      margin-right: 0.25rem;
      transform: rotate(90deg);
      width: 1rem;
    }

    &:hover .icon {
      fill: $hover-link-color;
    }
  }

  &__share {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
  }
}

.article {
  @include mq($until: M) {
    padding-top: 3rem;
  }

  .panel-container {
    @include mq($until: M) {
      padding-top: 0;
    }
  }
}

.article-list__articles {
  padding: 2.5rem 0 0;

  @include mq($from: M) {
    padding: $space * 2 0 $space;
  }

  @include mq($from: L) {
    padding: $space * 4 0 $space * 3;
  }
}

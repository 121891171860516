@import "../../variables";
// @import "../../mixins";
@import "../../mq";

.features-block {
  text-align: center;

  @include mq($from: M) {
    margin-bottom: -$space * 2;
  }

  &__items {
    list-style: none;

    @include mq($from: S) {
      display: flex;
      flex-wrap: wrap;

      .panel--halign-center & {
        justify-content: center;
      }

      .panel--halign-right & {
        justify-content: flex-end;
      }
    }

    li {
      margin-bottom: $space * 2;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }

      @include mq($from: S) {
        margin-left: $space;
        width: calc(50% - #{$space/2});

        &:nth-child(odd) {
          margin-left: 0;
        }
      }

      @include mq($from: M) {
        width: calc(33% - 12px);

        &:nth-child(odd) {
          margin-left: $space;
        }

        &:nth-child(3n + 1) {
          margin-left: 0;
        }
      }

      @include mq($from: L) {
        width: calc(25% - 15px);

        &:nth-child(3n + 1) {
          margin-left: $space;
        }

        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }

      @include mq($from: XXL) {
        width: calc(20% - 16px);

        &:nth-child(4n + 1) {
          margin-left: $space;
        }

        &:nth-child(5n + 1) {
          margin-left: 0;
        }
      }
    }
  }

  &__icon-container {
    align-items: center;
    border: 1px solid $color-default;
    display: flex;
    height: 75px;
    justify-content: center;
    margin: 0 auto $baseline;
    position: relative;
    transform: skew($default-skew) translateX(12px);
    width: 65px;

    .theme--default .panel--inverted &,
    .panel--dark & {
      border-color: $white;
    }

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        border-color: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        border-color: $color-default;
      }
    }
  }

  &__icon {
    fill: $color-default;
    max-height: $space * 2;
    max-width: $space * 2;

    .theme--default .panel--inverted &,
    .panel--dark & {
      fill: $white;
    }

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        fill: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        fill: $color-default;
      }
    }
  }

  &__title {
    font-size: $h3-sm;
    margin-bottom: 0;
    margin-top: 0;

    .theme--default .panel--inverted &,
    .panel--dark & {
      -webkit-text-fill-color: $white;
    }

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        -webkit-text-fill-color: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        -webkit-text-fill-color: $color-default;
      }
    }

    .theme--big-bang .panel--dark &,
    .panel--theme-big-bang.panel--dark & {
      &,
      body:not(.theme--big-bang) & {
        -webkit-text-fill-color: $color-big-bang;
      }
    }

    .theme--competition .panel--dark &,
    .panel--theme-competition.panel--dark & {
      &,
      body:not(.theme--competition) & {
        -webkit-text-fill-color: $color-competition;
      }
    }

    .theme--digital .panel--dark &,
    .panel--theme-digital.panel--dark & {
      &,
      body:not(.theme--digital) & {
        -webkit-text-fill-color: $color-digital;
      }
    }

    .theme--at-school .panel--dark &,
    .panel--theme-at-school.panel--dark & {
      &,
      body:not(.theme--at-school) & {
        -webkit-text-fill-color: $color-at-school;
      }
    }

    .theme--fair .panel--dark &,
    .panel--theme-fair.panel--dark & {
      &,
      body:not(.theme--fair) & {
        -webkit-text-fill-color: $color-fair;
      }
    }
  }

  &__content {
    color: $color-default;
    word-break: break-word;

    p {
      font-size: 0.8rem;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .theme--default .panel--inverted &,
    .panel--dark & {
      color: $white;
    }

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        color: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        color: $color-default;
      }
    }
  }

  &__link {
    display: block;
    text-decoration: none;
    transition: opacity $transition-speed/2 $transition-timing;

    &:hover {
      opacity: 0.5;
    }

    .panel--theme-default.panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted &,
    .panel--dark & {
      color: $white;
    }
  }
}

@import "../variables";
// @import "../mixins";
@import "../mq";

.primary-header {
  position: relative;
  height: 70px;
  padding: 0 20px;
  background-color: $color-default;
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mq($from: XL) {
    position: absolute;
    top: 0;
    z-index: 3;
    align-items: stretch;
    width: 325px;
    padding: 0;
    border-bottom: 0;
    border-right: 1px solid #fff;
    transform: skew($default-skew);
    transform-origin: 0 0;
    height: 75vh;
    min-height: 730px;
  }

  &__inner {
    @include mq($from: XL) {
      transform: skew(-$default-skew);
      transform-origin: 0 0;
    }
  }

  &__logo {
    svg {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      color: var(--theme-color);
    }

    @include mq($until: XL) {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 100;
      width: 90px;

      svg {
        width: 90px;
        height: 81px;
      }
    }

    @include mq($from: XL) {
      padding: 30px 0;
      display: flex;
      justify-content: center;

      > a {
        display: block;
        min-width: 0;
      }
    }
  }
}

@import "../../variables";
// @import "../../mixins";
// @import "../../mq";

.iframe-block {
  max-width: 100%;

  iframe {
    background-color: $white;
    border: 0;
    display: block;
    height: calc(100vh - #{$space * 8});
    margin: auto;
    min-width: 100%;
    width: 1px;
  }
}

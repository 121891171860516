@import "../variables";

.cookieOpenButton {
  z-index: 2147483647;
  content: "";
  width: 80px;
  height: 80px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: unset !important;
  outline: unset !important;

  &--background {
    fill: white;
    border-bottom: 80px solid $deep-blue;
    border-right: 80px solid transparent;
  }

  svg {
    fill: white;
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 25px;
    height: 25px;
  }
}

body #ccc {
  font-family: $base-font;

  #ccc-recommended-settings,
  #ccc-dismiss-button {
    @extend .btn;
    padding: 10px 0 11px !important;

    &:hover {
      padding-right: 30px !important;
    }
  }

  #ccc-recommended-settings {
    &,
    span {
      color: $white;
    }

    &:hover {
      &,
      span {
        color: $white;
      }
    }
  }

  #ccc-dismiss-button {
    background: transparent;
    border: none;

    &::before {
      content: "";
      display: block;
      position: absolute;
      background-color: transparent;
      border: 2px solid $primary-highlight-color;
      height: 100%;
      left: -$space * 1.5;
      top: 0;
      transform: skew($default-skew);
      transition: transform $transition-speed $transition-timing;
      width: calc(100% + #{$space * 3});
      z-index: 1;
    }

    &::after {
      background-image: url("/img/dist/sprite/arrow.svg");
    }

    &:hover {
      border-color: $white;
    }
  }

  #ccc-button-holder,
  #ccc-end {
    text-align: center;
  }

  .ccc-content--light {
    background-color: $white;
    color: $black;

    .ccc-button-solid {
      span {
        background: none;
        color: $black;
        font-weight: normal;
      }
    }

    h2 {
      letter-spacing: normal;
      -webkit-text-fill-color: unset;
      -webkit-text-stroke-width: 0;
    }
  }

  .ccc-content--dark {
    background-color: $deep-blue;
    color: $white;

    .ccc-button-solid {
      span {
        background: none;
        color: $white;
        font-weight: normal;
      }
    }

    h2 {
      letter-spacing: normal;
      -webkit-text-fill-color: unset;
      -webkit-text-stroke-width: 0;
    }
  }

  .checkbox-toggle--dark .checkbox-toggle-toggle {
    background-color: $brand-tertiary;
  }
}

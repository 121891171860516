@import "variables";
@import "mixins";
@import "mq";

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  line-height: $baseline;
  font-family: $base-font;
  background-color: $body-background-color;
  color: $default-text-color;
  min-height: 100%;
  min-height: 100vh;
  overflow-x: hidden;

  @include fluid-type(font-size, $minScreen, $maxScreen, $minFont, $maxFont);
}

h1 {
  @include headingFont($h1-sm, $h1-lg);
}

h2 {
  @include headingFont($h2-sm, $h2-lg);
}

h3 {
  @include headingFont($h3-sm, $h3-sm);
}

h4 {
  @include headingFont($h4-sm, $h4-lg);
}

h5,
h6 {
  @include headingFont($h5-sm, $h5-lg);
}

h3,
h4,
h5,
h6 {
  color: $color-default;
  letter-spacing: normal;
  -webkit-text-fill-color: unset;
  -webkit-text-stroke-width: 0;
}

p {
  margin-top: 0;
  line-height: $baseline;
  margin-bottom: $baseline;
}

a {
  color: $default-link-color;
  text-decoration: underline;

  &:hover {
    color: $hover-link-color;
    text-decoration: none;
  }

  &[data-fancybox="gallery"] {
    @include mq($until: S) {
      pointer-events: none;
    }
  }
}

ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

table {
  border: 0;
  height: auto;
  line-height: 1.25;
  margin: 0 0 $baseline;

  td,
  th {
    border: 0;
    padding: $baseline;
    position: relative;

    @include mq($until: S) {
      border-bottom: 1px solid $border-color;
      display: block;
      margin-bottom: $baseline;
      padding: 0 0 $baseline;
      width: 100% !important;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    *:last-child {
      margin-bottom: 0;
    }

    &::after {
      background-color: $border-color;
      display: block;
      height: calc(100% - 32px);
      position: absolute;
      right: 0;
      top: 16px;
      width: 1px;

      @include mq($from: S) {
        content: "";
      }
    }

    &:last-child::after {
      content: none;
    }
  }

  thead,
  tbody:first-child tr:first-child {
    th {
      background-color: $lightest-grey;
      border-bottom: 0;
    }
  }

  tbody {
    th {
      font-weight: normal;

      strong {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    tr:first-child th {
      font-weight: bold;
    }
  }

  tr:last-child {
    th,
    td {
      @include mq($from: S) {
        border-bottom: 0;
      }
    }
  }
}

hr {
  background-color: $default-text-color;
  border: 0;
  margin: $baseline * 2 0;
}

img {
  margin-bottom: 1em;
}

img[data-sizes="auto"] {
  display: block;
  width: 100%;
}

// Themes
.theme--default,
%theme-default {
  --theme-color: #{$color-big-bang};
  --theme-color-contrast: #{$white};
  --theme-color-foreground: #{$white};
  --theme-color-background: #{$color-big-bang};
  --theme-color-foreground-contrast: #{$white};
  --theme-color-background-contrast: #{$color-default};
  --theme-pattern: url("/img/dist/sprite/pattern_big-bang.svg");
  --theme-pattern-inverted: url("/img/dist/sprite/pattern_lines-white.svg");
  --theme-pattern-white: url("/img/dist/sprite/pattern_lines-white.svg");
}
.theme--big-bang,
%theme-big-bang {
  --theme-color: #{$color-big-bang};
  --theme-color-contrast: #{$white};
  --theme-color-foreground: #{$white};
  --theme-color-background: #{$color-big-bang};
  --theme-color-foreground-contrast: #{$white};
  --theme-color-background-contrast: #{$color-default};
  --theme-pattern: url("/img/dist/sprite/pattern_big-bang.svg");
  --theme-pattern-inverted: url("/img/dist/sprite/pattern_lines-white.svg");
  --theme-pattern-white: url("/img/dist/sprite/pattern_lines-white.svg");
}

.theme--competition,
%theme-competition {
  --theme-color: #{$color-competition};
  --theme-color-contrast: #{$color-default};
  --theme-color-foreground: #{$color-default};
  --theme-color-background: #{$color-competition};
  --theme-color-foreground-contrast: #{$white};
  --theme-color-background-contrast: #{$color-default};
  --theme-pattern: url("/img/dist/sprite/pattern_competition.svg");
  --theme-pattern-inverted: url("/img/dist/sprite/pattern_zigzag-default.svg");
  --theme-pattern-white: url("/img/dist/sprite/pattern_zigzag-white.svg");
}

.theme--digital,
%theme-digital {
  --theme-color: #{$color-digital};
  --theme-color-contrast: #{$white};
  --theme-color-foreground: #{$white};
  --theme-color-background: #{$color-digital};
  --theme-color-foreground-contrast: #{$color-default};
  --theme-color-background-contrast: #{$white};
  --theme-pattern: url("/img/dist/sprite/pattern_digital.svg");
  --theme-pattern-inverted: url("/img/dist/sprite/pattern_wave-white.svg");
  --theme-pattern-white: url("/img/dist/sprite/pattern_wave-white.svg");
  --theme-pattern-white: url("/img/dist/sprite/pattern_wave-white.svg");
}

.theme--at-school,
%theme-at-school {
  --theme-color: #{$color-at-school};
  --theme-color-contrast: #{$color-default};
  --theme-color-foreground: #{$color-default};
  --theme-color-background: #{$color-at-school};
  --theme-color-foreground-contrast: #{$white};
  --theme-color-background-contrast: #{$color-default};
  --theme-pattern: url("/img/dist/sprite/pattern_school.svg");
  --theme-pattern-inverted: url("/img/dist/sprite/pattern_wave-default.svg");
  --theme-pattern-white: url("/img/dist/sprite/pattern_wave-white.svg");
}

.theme--fair,
%theme-fair {
  --theme-color: #{$color-fair};
  --theme-color-contrast: #{$color-default};
  --theme-color-foreground: #{$color-default};
  --theme-color-background: #{$color-fair};
  --theme-color-foreground-contrast: #{$white};
  --theme-color-background-contrast: #{$color-default};
  --theme-pattern: url("/img/dist/sprite/pattern_fair.svg");
  --theme-pattern-inverted: url("/img/dist/sprite/pattern_zigzag-default.svg");
  --theme-pattern-white: url("/img/dist/sprite/pattern_zigzag-white.svg");
}

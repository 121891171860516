@import "../../variables";
@import "../../mixins";
@import "../../mq";

%panel {
  padding: $baseline 0;

  @include mq($from: M) {
    padding: $space * 4 0;
  }

  .panel:not(.panel--has-background):not(.panel--dark):not(.panel--inverted)
    + .panel:not(.panel--dark):not(.panel--inverted)
    &,
  .panel--inverted.panel--theme-default
    + .panel--inverted.panel--theme-default
    &,
  .panel--inverted.panel--theme-big-bang
    + .panel--inverted.panel--theme-big-bang
    &,
  .panel--inverted.panel--theme-competition
    + .panel--inverted.panel--theme-competition
    &,
  .panel--inverted.panel--theme-digital
    + .panel--inverted.panel--theme-digital
    &,
  .panel--inverted.panel--theme-at-school
    + .panel--inverted.panel--theme-at-school
    &,
  .panel--inverted.panel--theme-fair + .panel--inverted.panel--theme-fair &,
  .panel--dark + .panel--dark & {
    padding-top: 0;

    @include mq($from: L) {
      padding-top: 0;
    }
  }

  .panel--no-top-padding &,
  body.no-header-footer .panel:first-child & {
    padding-top: 0;
  }

  body.no-header-footer .panel:last-child &,
  .panel--no-bottom-padding & {
    padding-bottom: 0;
  }
}

.panel-container {
  @include mq($until: M) {
    padding-top: $baseline;
  }
}

.panel {
  position: relative;

  &::before {
    background-color: $primary-highlight-color;
    color: $white;
    content: none; // Set this to attr(data-internal) to show internal names on the front end
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 4px 8px 5px;
    position: absolute;
    top: 0;
    z-index: 9998;
  }

  &__inner {
    position: relative;
    z-index: 2;
  }

  &__ctas {
    padding-bottom: $space * 2;
    position: relative;
    z-index: 2;

    @include mq($from: S) {
      margin-top: -$space;
    }

    @include mq($from: L) {
      margin-top: -$space * 2;
      padding-bottom: $space * 4;
    }

    a.btn {
      margin-bottom: $space;

      @include mq($from: L) {
        margin: 0 $space * 3.5 0 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__background {
    @include mq($from: L) {
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100%;
    }

    &-image-container {
      height: 100%;
    }

    &-image {
      @include object-fit(cover, center);
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &--halign-center {
    @include mq($from: M) {
      text-align: center;
    }
  }

  &--halign-right {
    @include mq($from: M) {
      text-align: right;
    }
  }
  // Themes

  &--theme-default {
    @extend %theme-default;
  }

  &--theme-competition {
    @extend %theme-competition;
  }

  &--theme-digital {
    @extend %theme-digital;
  }

  &--theme-at-school {
    @extend %theme-at-school;
  }

  &--theme-fair {
    @extend %theme-fair;
  }

  &--inverted:not(.panel--tabbed-content-panel) {
    background-color: var(--theme-color);
    color: var(--theme-color-contrast);
  }

  &--dark:not(.panel--tabbed-content-panel) {
    background-color: $color-default;
    color: $white;
  }

  &--full-width {
    .panel__inner {
      padding-bottom: 0;
      padding-top: 0;
    }

    .constrain {
      max-width: none;
      width: 100%;
    }
  }
}

@import "../../variables";
@import "../../mixins";
@import "../../mq";

.accordion-block {
  $comp: &;

  &__item {
    padding: 1px 0 0;
    margin: 0 0 20px;
    border: 1px solid $color-default;
    position: relative;
    background: #fff;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      z-index: -1;
      transition: width $transition-speed $transition-timing,
        height $transition-speed $transition-timing;
    }

    &::before {
      top: -1px;
      bottom: -1px;
      right: calc(100% + 1px);
      width: 0;
      transform: skewY(-45deg);
      background: $color-default;
      transform-origin: right top;
      will-change: width;
    }
    // Bottom
    &::after {
      left: -1px;
      right: -1px;
      top: calc(100% + 1px);
      height: 0;
      transform: skewX(-45deg);
      background: $color-default;
      transform-origin: left top;
      will-change: height;
    }
  }

  &__title {
    @include headingFont($h4-sm, $h4-lg);
    cursor: pointer;
    letter-spacing: 0;
    margin: 0;
    color: $color-default;
    padding: 22px 30px 22px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    -webkit-text-fill-color: inherit;
    -webkit-text-stroke-width: 0;
    transition: color $transition-speed $transition-timing;

    &:hover,
    &:active {
      @include mq($from: M) {
        color: $color-default;
      }
    }
  }

  &__title-icon {
    background-color: var(--theme-color-background);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 30px;
    transform: skew($default-skew);
    transition: transform $transition-speed $transition-timing;
    margin-left: 20px;
    flex: none;

    .accordion-block__title:hover &,
    &:hover,
    &:focus,
    &:active {
      background-color: var(--theme-color-background);
      transform: skew(0);

      .panel--inverted & {
        background-color: var(--theme-color-background-contrast);
      }
    }

    .panel--inverted & {
      background-color: var(--theme-color-background-contrast);
    }

    &::before {
      content: "\002b";
      display: block;
      font-style: normal;
      font-weight: normal;
      font-family: $base-font;
      line-height: 1;
      font-size: 21px;
      width: 100%;
      color: var(--theme-color-foreground);
      text-align: center;
      transition: transform $transition-speed $transition-timing;

      .panel--inverted & {
        color: var(--theme-color-foreground-contrast);
      }
    }

    @include mq($from: XL) {
      width: 36px;
      height: 40px;

      &::before {
        font-size: 30px;
      }
    }
  }

  &__content {
    background-color: transparent;
    min-height: 0;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: max-height 0.2s ease-out;
    color: $color-default;
  }

  &__inner {
    padding: 0 20px 22px 20px;

    > *:last-child {
      margin: 0;
    }

    ul,
    ol {
      margin-bottom: $baseline;
    }

    ul li {
      margin-bottom: $space/2;
      padding-left: 25px;
      position: relative;

      &::before {
        background-color: $color-default;
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 8px;
        left: 0;
      }
    }
  }

  &__item.active {
    &::before {
      width: 10px;
    }

    &::after {
      height: 10px;
    }

    #{$comp}__title-icon::before {
      transform: rotate(45deg * 3);
      transform-origin: 50% 50%;
    }
  }

  &__item:hover,
  &__item:active {
    @include mq($from: M) {
      &:before {
        width: 10px;
      }

      &:after {
        height: 10px;
      }
    }
  }
}

@import "../variables";
//@import "../mixins";
@import "../mq";

.sitemap {
  line-height: 2;
  list-style-type: circle;
  list-style-position: inside;
  margin-bottom: 0;
  margin-left: 1rem;

  &--level-1 {
    list-style-type: square;
    list-style-position: inside;
    padding: $space 0;

    @include mq($from: M) {
      padding: $space * 2 0;
    }

    > li {
      padding-bottom: 10px;
    }
  }

  &__subsection {
    padding-left: 20px;
  }
}

@import "../variables";
// @import "../mixins";
@import "../mq";

%btn,
input[type="submit"] {
  background-color: $primary-highlight-color;
  border: none;
  color: $white;
  cursor: pointer;
  display: block;
  padding: 10px 0 11px;
  position: relative;
  text-decoration: none;
  text-align: center;
  transform: translateX(15px);
  transition: padding $transition-speed $transition-timing;
  vertical-align: middle;
  width: calc(100% - #{$space * 1.75});

  @include mq($from: M) {
    display: inline-block;
    transform: translateX($space * 1.5);
    width: calc(100% - 32px);
  }

  @include mq($from: L) {
    transform: translateX($space);
    width: auto;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
  }

  &::before {
    background-color: $primary-highlight-color;
    height: 100%;
    left: -$space * 1.5;
    top: 0;
    transform: skew($default-skew);
    transition: transform $transition-speed $transition-timing;
    width: calc(100% + #{$space * 3});
    z-index: -1;
  }

  &::after {
    background: url("/img/dist/sprite/arrow-white.svg") no-repeat;
    background-size: 100%;
    height: 24px;
    opacity: 0;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    transition: all $transition-speed $transition-timing;
    width: 24px;
  }

  &:hover {
    color: $white;

    @include mq($from: M) {
      padding-right: $space * 1.5;
    }

    &::before {
      transform: none;
    }

    &::after {
      opacity: 1;
      right: -10px;
    }
  }
}

.btn,
span.btn > a,
a > span.btn {
  @extend %btn;

  @include mq($from: L) {
    &.btn--fw {
      width: 100%;
    }
  }
}

span.btn {
  background: none;
  padding: 0;

  @include mq($from: M) {
    transform: translateX(15px);
  }

  &::before,
  &::after {
    content: none;
  }
}

a.btn,
a > span.btn {
  text-decoration: none;

  @include mq($until: M) {
    transform: translateX($space * 1.5);
    width: calc(100% - #{$space * 3.5});
  }
}

// Primary buttons
a.btn--primary,
input.btn--primary,
button.btn--primary,
.btn--primary > a {
  @extend %btn;
}

// Alternative buttons
a.btn--secondary,
input.btn--secondary,
button.btn--secondary,
.btn--secondary > a {
  @extend %btn;
  background-color: $color-big-bang;

  &::before {
    background-color: $color-big-bang;
  }

  .theme--competition &,
  .panel--theme-competition & {
    &,
    body:not(.theme--competiton) & {
      background-color: $color-competition;
      color: $color-default;

      &::before {
        background-color: $color-competition;
      }

      &::after {
        background-image: url("/img/dist/sprite/arrow.svg");
      }
    }
  }

  .theme--digital &,
  .panel--theme-digital & {
    &,
    body:not(.theme--digital) & {
      background-color: $color-digital;

      &::before {
        background-color: $color-digital;
      }
    }
  }

  .theme--at-school &,
  .panel--theme-at-school & {
    &,
    body:not(.theme--at-school) & {
      background-color: $color-at-school;
      color: $color-default;

      &::before {
        background-color: $color-at-school;
      }

      &::after {
        background-image: url("/img/dist/sprite/arrow.svg");
      }
    }
  }

  .theme--fair &,
  .panel--theme-fair & {
    &,
    body:not(.theme--fair) & {
      background-color: $color-fair;
      color: $color-default;

      &::before {
        background-color: $color-fair;
      }

      &::after {
        background-image: url("/img/dist/sprite/arrow.svg");
      }
    }
  }
}

a.btn--prev,
input.btn--prev,
button.btn--prev,
.btn--prev > a {
  &::after {
    left: -30px;
    transform: rotate(180deg) translateY(50%);
  }

  &:hover {
    padding-left: $space * 1.5;
    padding-right: 0;

    &::after {
      opacity: 1;
      left: -10px;
    }
  }
}

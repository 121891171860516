@import "../../variables";
@import "../../mixins";
@import "../../mq";

.gallery-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 (-2.5rem);
  position: relative;

  @include fluid-type(
    margin-left margin-right,
    $minScreen,
    $maxScreen,
    -0.625rem,
    -1.25rem
  );

  &--as-carousel {
    display: block;
    margin: 0;

    @include mq($from: L) {
      .slick-list {
        @include fluid-type(
          margin-left margin-right,
          $minScreen,
          $maxScreen,
          -0.625rem,
          -1.25rem
        );
      }
    }
  }

  &__image-container {
    margin-bottom: 2.5rem;

    @include fluid-type(
      padding-left padding-right,
      $minScreen,
      $maxScreen,
      0.625rem,
      1.25rem
    );

    @include mq($from: S) {
      height: 240px;
      width: 50%;
    }

    @include mq($from: M) {
      width: 33%;

      .content-panel__row--two-column & {
        width: 50%;
      }
    }

    @include mq($from: XXL) {
      width: 25%;
    }

    .content-panel__row--one-column & {
      &:first-child {
        @include mq($from: S) {
          height: 360px;
          width: 100%;
        }

        @include mq($from: M) {
          height: 440px;
        }

        @include mq($from: XXL) {
          height: 480px;
        }
      }
    }

    picture {
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: block;
    }

    .image-grid-block__items--as-carousel & {
      height: 240px;
      margin-bottom: 0;
      width: auto;

      @include mq($from: S) {
        height: 320px;
      }
    }
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin: 0;
    min-height: $space * 10;
    padding: 0;
    transition: transform $transition-speed * 3 $transition-timing;
    width: 100%;

    a:hover & {
      transform: scale(1.1);
    }
  }
}

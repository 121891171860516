@import "../variables";
@import "../mixins";
// @import "../mq";

%field {
  background: $form-background-color;
  border: 1px solid $form-border-color;
  border-radius: $border-radius/2;
  box-sizing: border-box;
  font-size: 0.81818rem;
  height: 48px;
  margin: 0;
  max-width: 100% !important; // 100% rather than unset for IE11
  outline: none;
  padding: 0 12px;
  transition: border-color $transition-speed $transition-timing,
    background-color $transition-speed $transition-timing;
  width: 100%;

  &:focus {
    border-color: $default-text-color;
    outline: none;
  }
}

select {
  &,
  div.umbraco-forms-form & {
    @extend %field;
    background-repeat: no-repeat;
    background-position: right 0.6rem center;
    background-image: url("/img/dist/sprite/select-arrow.svg");
    line-height: normal;
    padding-bottom: 0;
    padding-right: 30px;
    padding-top: 0;

    &::-ms-expand {
      display: none;
    }
  }
}

input[type="text"],
input[type="search"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="week"],
input[type="month"],
input[type="time"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="color"],
input[type="file"],
textarea {
  &,
  div.umbraco-forms-form & {
    @extend %field;
    // For Firefox
    &:-moz-read-only {
      cursor: default;
      background: $form-border-color;
    }

    &:read-only {
      cursor: default;
      background: $form-border-color;
    }
  }
}

input[type="file"] {
  &,
  div.umbraco-forms-form & {
    padding-top: 6px;
  }
}

textarea {
  &,
  div.umbraco-forms-form & {
    padding: 12px;
  }
}
// Placeholders
::-webkit-input-placeholder {
  color: $form-placeholder-color;
}

:-ms-input-placeholder {
  color: $form-placeholder-color;
}

::placeholder {
  color: $form-placeholder-color;
}
// Umbraco Forms
div.umbraco-forms-form {
  max-width: 60rem;
  padding: 0;

  fieldset {
    margin: 0 !important;
    padding: 0 !important;
  }

  .umbraco-forms-indicator {
    color: $color-big-bang;

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        color: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        color: $color-big-bang;
      }
    }
  }

  form {
    [class*="col-"],
    [class*="block-row-"] > * {
      border-left-width: 8px;
      border-right-width: 8px;
    }

    legend {
      left: -9999px;
      position: absolute;
    }

    .umbraco-forms-field {
      @include clear;
      margin-bottom: 24px;
      position: relative;
    }

    .umbraco-forms-field-wrapper {
      position: relative;
      margin-top: ($space/2);
      width: 100%;

      h4 {
        margin-bottom: $baseline/2;
      }

      p {
        font-size: 0.81818rem;
        margin-bottom: $baseline/2;
      }
    }

    .umbraco-forms-label,
    .umbraco-forms-tooltip {
      color: $form-label-color;
      display: block;
      margin-bottom: 0;

      .theme--default .panel--inverted &,
      .panel--dark & {
        color: $white;
      }

      .theme--big-bang .panel--inverted &,
      .theme--digital .panel--inverted &,
      .panel--theme-big-bang.panel--inverted &,
      .panel--theme-digital.panel--inverted & {
        &,
        body:not(.theme--big-bang):not(.theme--digital) & {
          color: $white;
        }
      }

      .theme--competition .panel--inverted &,
      .theme--at-school .panel--inverted &,
      .theme--fair .panel--inverted &,
      .panel--theme-competition.panel--inverted &,
      .panel--theme-at-school.panel--inverted &,
      .panel--theme-fair.panel--inverted & {
        &,
        body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
          & {
          color: $form-label-color;
        }
      }
    }

    .umbraco-forms-label {
      margin-right: 0.5rem;
    }

    .umbraco-forms-tooltip {
      font-size: 0.7rem;
      padding-top: 2px;
      transform: translateY(2px);
    }

    .multiplechoice .umbraco-forms-label,
    .singlechoice .umbraco-forms-label {
      @include clear;
      margin-bottom: ($space/2);
    }

    .note,
    .help-block {
      margin-bottom: 0;
      color: $form-tooltip-color;
      display: block;
      line-height: normal;
    }

    .checkbox__psuedo-elements,
    .dataconsent label {
      display: inline-block;
    }
  }

  // Radios
  .radio-container {
    display: block;
    position: relative;
    padding-left: 40px;
    padding-top: 5px;
    cursor: pointer;
    color: $form-label-color;
    user-select: none;
    margin-bottom: $space;

    .theme--default .panel--inverted &,
    .panel--dark & {
      color: $white;
    }

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        color: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        color: $form-label-color;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: $white;
      border: 1px solid $form-border-color;

      .theme--default .panel--inverted &,
      .panel--dark & {
        border-color: $white;
      }

      .theme--big-bang .panel--inverted &,
      .theme--digital .panel--inverted &,
      .panel--theme-big-bang.panel--inverted &,
      .panel--theme-digital.panel--inverted & {
        &,
        body:not(.theme--big-bang):not(.theme--digital) & {
          border-color: $white;
        }
      }

      .theme--competition .panel--inverted &,
      .theme--at-school .panel--inverted &,
      .theme--fair .panel--inverted &,
      .panel--theme-competition.panel--inverted &,
      .panel--theme-at-school.panel--inverted &,
      .panel--theme-fair.panel--inverted & {
        &,
        body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
          & {
          border-color: $form-border-color;
        }
      }
    }

    &:hover input ~ .checkmark {
      border: 1px solid $color-default;

      .theme--default .panel--inverted &,
      .panel--dark & {
        border-color: $white;
      }

      .theme--big-bang .panel--inverted &,
      .theme--digital .panel--inverted &,
      .panel--theme-big-bang.panel--inverted &,
      .panel--theme-digital.panel--inverted & {
        &,
        body:not(.theme--big-bang):not(.theme--digital) & {
          border-color: $white;
        }
      }

      .theme--competition .panel--inverted &,
      .theme--at-school .panel--inverted &,
      .theme--fair .panel--inverted &,
      .panel--theme-competition.panel--inverted &,
      .panel--theme-at-school.panel--inverted &,
      .panel--theme-fair.panel--inverted & {
        &,
        body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
          & {
          border-color: $color-default;
        }
      }
    }

    input:checked ~ .checkmark {
      background-color: $color-default;
      border: 1px solid $color-default;

      .theme--default .panel--inverted &,
      .panel--dark & {
        background-color: $white;
        border-color: $white;
      }

      .theme--big-bang .panel--inverted &,
      .theme--digital .panel--inverted &,
      .panel--theme-big-bang.panel--inverted &,
      .panel--theme-digital.panel--inverted & {
        &,
        body:not(.theme--big-bang):not(.theme--digital) & {
          background-color: $white;
          border-color: $white;
        }
      }

      .theme--competition .panel--inverted &,
      .theme--at-school .panel--inverted &,
      .theme--fair .panel--inverted &,
      .panel--theme-competition.panel--inverted &,
      .panel--theme-at-school.panel--inverted &,
      .panel--theme-fair.panel--inverted & {
        &,
        body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
          & {
          background-color: $color-default;
          border-color: $color-default;
        }
      }
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }
  // Data consent & checkbox lists
  .dataconsent {
    padding: 0;
  }

  span.dataconsent-container,
  span.checkboxlist {
    padding: 0;

    input[type="checkbox"] {
      left: -100%;
      opacity: 0;
      position: absolute;
      top: 0;

      &:checked + label::after {
        display: block;
      }

      &:focus + label::before {
        border-color: $color-default;

        .theme--default .panel--inverted &,
        .panel--dark & {
          border-color: $white;
        }

        .theme--big-bang .panel--inverted &,
        .theme--digital .panel--inverted &,
        .panel--theme-big-bang.panel--inverted &,
        .panel--theme-digital.panel--inverted & {
          &,
          body:not(.theme--big-bang):not(.theme--digital) & {
            border-color: $white;
          }
        }

        .theme--competition .panel--inverted &,
        .theme--at-school .panel--inverted &,
        .theme--fair .panel--inverted &,
        .panel--theme-competition.panel--inverted &,
        .panel--theme-at-school.panel--inverted &,
        .panel--theme-fair.panel--inverted & {
          &,
          body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
            & {
            border-color: $color-default;
          }
        }
      }
    }

    label {
      display: inline-block;
      padding: 2px 0 1px 2rem;
      margin-bottom: 8px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid $form-border-color;

        .theme--default .panel--inverted &,
        .panel--dark & {
          border-color: $white;
        }

        .theme--big-bang .panel--inverted &,
        .theme--digital .panel--inverted &,
        .panel--theme-big-bang.panel--inverted &,
        .panel--theme-digital.panel--inverted & {
          &,
          body:not(.theme--big-bang):not(.theme--digital) & {
            border-color: $white;
          }
        }

        .theme--competition .panel--inverted &,
        .theme--at-school .panel--inverted &,
        .theme--fair .panel--inverted &,
        .panel--theme-competition.panel--inverted &,
        .panel--theme-at-school.panel--inverted &,
        .panel--theme-fair.panel--inverted & {
          &,
          body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
            & {
            border-color: $color-default;
          }
        }
      }

      &::after {
        content: "";
        display: none;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: $color-default;

        .theme--default .panel--inverted &,
        .panel--dark & {
          background-color: $white;
        }

        .theme--big-bang .panel--inverted &,
        .theme--digital .panel--inverted &,
        .panel--theme-big-bang.panel--inverted &,
        .panel--theme-digital.panel--inverted & {
          &,
          body:not(.theme--big-bang):not(.theme--digital) & {
            background-color: $white;
          }
        }

        .theme--competition .panel--inverted &,
        .theme--at-school .panel--inverted &,
        .theme--fair .panel--inverted &,
        .panel--theme-competition.panel--inverted &,
        .panel--theme-at-school.panel--inverted &,
        .panel--theme-fair.panel--inverted & {
          &,
          body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
            & {
            background-color: $color-default;
          }
        }
      }
    }
  }
  // Radio button list
  .radiobuttonlist {
    .radio-wrapper {
      display: block;
      width: 100%;
      margin: 0 0 ($space/2);
    }

    input[type="radio"] {
      left: -100%;
      opacity: 0;
      position: absolute;
      top: 0;

      &:checked + label::after {
        display: block;
      }

      &:focus + label::before {
        border-color: $color-default;

        .theme--default .panel--inverted &,
        .panel--dark & {
          border-color: $white;
        }

        .theme--big-bang .panel--inverted &,
        .theme--digital .panel--inverted &,
        .panel--theme-big-bang.panel--inverted &,
        .panel--theme-digital.panel--inverted & {
          &,
          body:not(.theme--big-bang):not(.theme--digital) & {
            border-color: $white;
          }
        }

        .theme--competition .panel--inverted &,
        .theme--at-school .panel--inverted &,
        .theme--fair .panel--inverted &,
        .panel--theme-competition.panel--inverted &,
        .panel--theme-at-school.panel--inverted &,
        .panel--theme-fair.panel--inverted & {
          &,
          body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
            & {
            border-color: $color-default;
          }
        }
      }
    }

    label {
      display: inline-block;
      padding: 2px 0 1px 2rem;
      margin-bottom: 8px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        border: 1px solid $form-border-color;

        .theme--default .panel--inverted &,
        .panel--dark & {
          border-color: $white;
        }

        .theme--big-bang .panel--inverted &,
        .theme--digital .panel--inverted &,
        .panel--theme-big-bang.panel--inverted &,
        .panel--theme-digital.panel--inverted & {
          &,
          body:not(.theme--big-bang):not(.theme--digital) & {
            border-color: $white;
          }
        }

        .theme--competition .panel--inverted &,
        .theme--at-school .panel--inverted &,
        .theme--fair .panel--inverted &,
        .panel--theme-competition.panel--inverted &,
        .panel--theme-at-school.panel--inverted &,
        .panel--theme-fair.panel--inverted & {
          &,
          body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
            & {
            border-color: $color-default;
          }
        }
      }

      &::after {
        content: "";
        display: none;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 50%;
        background-color: $color-default;

        .theme--default .panel--inverted &,
        .panel--dark & {
          background-color: $white;
        }

        .theme--big-bang .panel--inverted &,
        .theme--digital .panel--inverted &,
        .panel--theme-big-bang.panel--inverted &,
        .panel--theme-digital.panel--inverted & {
          &,
          body:not(.theme--big-bang):not(.theme--digital) & {
            background-color: $white;
          }
        }

        .theme--competition .panel--inverted &,
        .theme--at-school .panel--inverted &,
        .theme--fair .panel--inverted &,
        .panel--theme-competition.panel--inverted &,
        .panel--theme-at-school.panel--inverted &,
        .panel--theme-fair.panel--inverted & {
          &,
          body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
            & {
            background-color: $color-default;
          }
        }
      }
    }
  }

  label,
  .umbraco-forms-label {
    display: block;
    font-size: 0.81818rem;
    font-weight: normal;
    color: $form-label-color;
    line-height: normal;
    margin-bottom: 8px;

    .theme--default .panel--inverted &,
    .panel--dark & {
      color: $white;
    }

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        color: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        color: $color-default;
      }
    }
  }

  .umbraco-forms-form input.text,
  .umbraco-forms-form textarea {
    max-width: 100% !important;
    padding: 10px 12px;
    width: 100%;
  }
  // Recaptcha fixes
  .recaptchatable input[type="text"] {
    line-height: 18px !important;
  }

  #recaptcha_area,
  #recaptcha_table {
    table-layout: auto !important;
  }

  .recaptcha_is_showing_audio embed {
    height: 0;
    width: 0;
  }

  #recaptcha_area input[type="text"] {
    display: inline-block !important;
    height: auto !important;
  }

  #recaptcha_table td {
    line-height: normal;
  }
  // Generic validation
  span.field-validation-error,
  input.input-validation-error,
  textarea.input-validation-error,
  select.input-validation-error {
    border-color: $error;
  }

  span.field-validation-error {
    color: $error !important;
    display: block;
    font-size: 0.81818rem;
    margin: 0;
    padding: 8px 0 0;
    position: relative;
  }

  .validation-summary-errors {
    color: $error;
    font-weight: bold;
    margin-bottom: $space;
    text-transform: uppercase;
  }

  input.input-validation-error,
  textarea.input-validation-error {
    border: 1px solid $error;
  }
}

.umbraco-forms-field-wrapper {
  .bigger {
    font-size: 1.15rem !important;
    font-weight: bold;
  }
}

.umbraco-forms-navigation {
  margin: 0;
  padding: 1rem 0 0;

  .col-s-12 {
    border: 0;

    @include mq($from: L) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .btn {
    transform: translateX(32px);
    width: auto;
  }

  .btn:first-child {
    @include mq($from: L) {
      transform: translateX(32px);
    }

    + .btn {
      margin-top: 1rem;

      @include mq($from: L) {
        margin-left: auto;
        margin-top: 0;
        transform: translateX(-32px);
      }
    }
  }
}

.form-popover {
  button.btn--primary {
    width: auto;
  }

  .umbraco-forms-caption {
    line-height: 1.2 !important;
  }
}
// Datepicker
.pika-single {
  border-color: $form-border-color;
  border-radius: $border-radius/2;

  button {
    border-radius: 0;
    min-height: 0;
    text-align: center;
  }

  td {
    border: 1px solid $white;
  }

  .pika-button {
    color: $default-text-color;
    padding: 8px;

    &:hover {
      background-color: $default-text-color;
    }
  }

  .is-today .pika-button {
    color: $primary-highlight-color;

    &:hover {
      color: $secondary-highlight-color;
    }
  }

  .pika-lendar {
    margin: 10px;
  }

  .pika-title {
    align-items: center;
    color: $default-text-color;
    display: flex;
    padding-bottom: 10px;

    select {
      border: 0;
      color: inherit;
      height: 1rem;
      top: 0;
    }
  }

  .pika-label {
    color: $default-text-color;
    float: none;
    font-family: $base-font;
    height: 1rem;
    order: 3;
    padding: 0 3px;

    &:first-of-type {
      order: 2;
    }
  }

  .pika-prev,
  .pika-next {
    background: url("/img/dist/sprite/arrow.svg") no-repeat left center;
    float: none;
    height: $space;
    opacity: 0.25;
  }

  .pika-prev {
    left: 3px;
    margin-right: auto;
    order: 1;
  }

  .pika-next {
    margin-left: auto;
    order: 4;
    right: 3px;
    transform: rotate(180deg);
  }

  .pika-select {
    padding: 0;
  }

  .pika-table {
    font-family: $base-font;
    margin: 0;

    abbr {
      text-decoration: none;
    }
  }
}

/**************************\
  Basic Modal Styles
\**************************/

.bb-modal {
  display: none;

  &--intro {
    display: block;
  }
}

.bb-modal.is-open {
  display: block;
}

.bb-modal[aria-hidden="false"] .bb-modal__inner {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.bb-modal[aria-hidden="false"] .bb-modal__dialog {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.bb-modal__container,
.bb-modal__inner {
  will-change: transform;
}

.bb-modal__inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  z-index: 1002;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
}

.bb-modal__dialog {
  background-color: #fff;
  position: relative;
  margin: auto 0;
  border: 1px solid var(--theme-color, $color-big-bang);

  @include fluid-type(
    padding-left padding-right,
    $minScreen,
    $maxScreen,
    1.25rem,
    3.75rem
  );

  @include fluid-type(
    padding-top padding-bottom,
    $minScreen,
    $maxScreen,
    1.25rem,
    2.5rem
  );

  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    transition: width $transition-speed $transition-timing,
      height $transition-speed $transition-timing;
  }

  //Left
  &::before {
    top: -1px;
    bottom: -1px;
    right: calc(100% + 1px);
    width: 10px;
    transform: skewY(-45deg);
    background: var(--theme-color, $color-big-bang);
    transform-origin: right top;
    will-change: width;
  }
  // Bottom
  &::after {
    left: -1px;
    right: -1px;
    top: calc(100% + 1px);
    height: 10px;
    transform: skewX(-45deg);
    background: var(--theme-color, $color-big-bang);
    transform-origin: left top;
    will-change: height;
  }

  @include mq($from: L) {
    width: $content-width;
    max-width: $max-content-width;
  }
}

.bb-modal__titlebar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
}

.bb-modal__title {
  @include headingFont($h3-sm, $h3-lg);
  -webkit-text-fill-color: $color-default;
  -webkit-text-stroke-color: transparent;
  word-wrap: break-word;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 0.25em;
  }
}

.bb-modal__meta {
  @include headingFont($h5-sm, $h5-lg);
  -webkit-text-fill-color: var(--theme-color, $color-big-bang);
  -webkit-text-stroke-color: transparent;
  word-wrap: break-word;
  margin: 0;
}

.bb-modal__content {
  [class*="-block"] {
    margin-bottom: $baseline;

    @include mq($from: M) {
      margin-bottom: $space * 2;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.bb-modal__video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.bb-modal__close {
  background: transparent;
  padding: 0;
  width: 26px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-color, $color-big-bang);
  border: 2px solid var(--theme-color, $color-big-bang);
  transform: skew($default-skew);
  flex: none;
  margin-left: 20px;
  text-decoration: none;

  .bb-modal--intro & {
    margin-left: auto;
  }

  &::before {
    content: "\2715";
    font-weight: bold;
    font-size: 16px;
    transition: transform 0.15s ease;
  }

  &:hover {
    background: none;
    color: var(--theme-color, $color-big-bang);

    &::before {
      transform: rotate(45deg * 4);
    }
  }

  &:focus {
    outline: none;
  }

  @include mq($from: XL) {
    width: 36px;
    height: 40px;

    &::before {
      font-size: 20px;
    }
  }
}

.bb-modal--hidden-by-default {
  display: none;
}

.bb-modal--dismissed {
  pointer-events: none;

  .bb-modal__inner {
    animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1) forwards !important;
  }

  .bb-modal__dialog {
    animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1) forwards !important;
  }
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

@import "../variables";
@import "../mixins";
@import "../mq";

.hero-container {
  position: relative;
}

.hero {
  background-color: $color-default;
  position: relative;
  overflow: hidden;
  color: var(--theme-color);
  z-index: 1;
  // Fuse bottom mobile
  &::after {
    content: "";
    display: block;
    background: url("/img/dist/sprite/hero_bottom-mobile.svg") no-repeat;
    background-size: contain;
    height: 1vw * (40/375 * 100);
    width: 1vw * (355/375 * 100);
    margin: $space * 2 0;
  }

  @include mq($from: XL) {
    $circle-height: 1vh * (485 / 900 * 100);
    $circle-offset: 1vh * (100 / 900 * 100);

    height: 75vh;
    min-height: 730px;
    display: flex;
    background: $color-default url("/img/dist/sprite/hero_circle.svg")
      calc(100% + #{$circle-offset}) -$circle-offset no-repeat;
    background-size: $circle-height auto;
    // Fuse top left
    &::before {
      content: "";
      position: absolute;
      background: url("/img/dist/sprite/hero_top-left.svg") no-repeat;
      background-size: cover;
      left: 320px;
      height: 1vh * (455/900 * 100);
      width: 1vh * (167/900 * 100);
      transform: translateY(-40%);
    }
    // Fuse bottom left
    &::after {
      background-image: url("/img/dist/sprite/hero_bottom-left.svg");
      height: 1vh * (220/900 * 100);
      width: 1vh * (566/900 * 100);
      margin: 0;
      position: absolute;
      z-index: 1;
      left: 100px;
      bottom: $space * 2;
    }
  }

  &__image-container {
    background: url("/img/dist/sprite/hero_circle.svg") 50% 50% no-repeat;
    background-size: 130%;
    height: 0;
    padding-bottom: percentage(308/375);
    position: relative;

    @include mq($from: XL) {
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      height: calc(100% - 120px);
      width: 55vw;
      background: none;
      padding: 0;
      // Bottom right fuse
      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: $space * 6;
        left: 22%;
        height: 1vh * (389/900 * 100);
        width: 1vh * (155/900 * 100);
        background: url("/img/dist/sprite/hero_bottom-right.svg") no-repeat;
        background-size: contain;
      }
    }
  }

  &__image {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    object-fit: contain;
    object-position: right bottom;
    display: block;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  &__inner {
    display: flex;
    align-items: center;
    border: 6px solid currentColor;
    transform: skew($default-skew);
    flex: 1;
    margin-right: $space * 2;
    margin-left: $space * 2;
    margin-top: -$space * 4;
    padding: $space;
    position: relative;
    z-index: 1;
    background: $color-default;

    @include mq($from: XL) {
      margin-top: $space * 3;
      margin-bottom: $space * 3;
      margin-right: $space * 5;
      margin-left: 520px;
      padding: 0;
      border: 0;
      z-index: auto;
      background: transparent;
      transform: none;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
      }
      // Frame
      &::before {
        border: 10px solid currentColor;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        transform: skew($default-skew);
      }
      // Fuse inner top left
      &::after {
        background: url("/img/dist/sprite/hero_top-right.svg") no-repeat;
        background-size: cover;
        top: $space * 2;
        left: $space * 4;
        height: 1vh * (158/900 * 100);
        width: 1vh * (390/900 * 100);
      }
    }
  }

  &__content {
    transform: skew(-$default-skew);

    @include mq($from: XL) {
      width: 40vw;
      transform: translateX(-200px);
      transform-origin: 50% 0;
      position: relative;
      z-index: 3;
      padding: $space / 2 0 $space;
      // Background border mask
      &:before {
        content: "";
        display: block;
        height: 100%;
        background: $color-default;
        position: absolute;
        left: 199px;
        top: 0;
        width: 12px; // Two pixels wider than border to mask hairline artifacts on 72dpi displays
        z-index: -1;
        transform: skew($default-skew);
      }
    }

    .btn {
      @include mq($until: XL) {
        transform: translateX(16px);
        width: calc(100% - 56px);
      }

      @include mq($from: XL) {
        transform: translateX(14px);
      }
    }
  }

  &__heading {
    @include headingFont(2.5rem, 5rem);
    font-style: normal;
    color: currentColor;
    line-height: 1.1;
    margin-bottom: 0.2em;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: currentColor;
    transform: skew($default-skew);

    &:last-child {
      margin-bottom: 0;
    }

    em {
      color: currentColor;
      letter-spacing: normal;
      text-shadow: none;
      -webkit-text-fill-color: currentColor;
      -webkit-text-stroke-width: 0;
      display: inline;
      font-style: inherit;
    }

    span {
      -webkit-text-stroke-width: 2px;
    }
  }
}

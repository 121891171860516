@import "../variables";
// @import "../mixins";
@import "../mq";

@mixin flyout {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1001;
  padding: 70px 0 0;
  width: 310px;
  background: var(--theme-color);
  color: var(--theme-color-contrast);
  display: flex;
}

@mixin menu-link {
  text-decoration: none;
  padding: 22px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  transition: background $transition-speed * 2 $transition-timing;

  @include mq($from: XL) {
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

@mixin menu-icon {
  width: 24px;
  height: 24px;
  flex: none;
  margin-left: 5px;
  fill: currentColor;
}

.primary-navigation {
  @include mq($from: XL) {
    transform: skew($default-skew);
    transform-origin: 0 0;
    overflow: hidden;
    margin-right: 28px;
  }

  &__inner {
    @include mq($until: XL) {
      @include flyout;

      visibility: hidden;
      opacity: 0;
      transform: translateX(100%);
      transition: visibility $transition-speed * 2 $transition-timing,
        opacity $transition-speed * 2 $transition-timing,
        transform $transition-speed * 2 $transition-timing;

      .active & {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &__list {
    border-top: 1px solid var(--theme-color-contrast);
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 20px 70px;

    @include mq($from: XL) {
      transform: skew(-$default-skew);
      transform-origin: 0 0;
      overflow: visible;
      padding: 0;
      border-top-color: $white;
    }
  }

  &__list-item {
    border-bottom: 1px solid var(--theme-color-contrast);

    @include mq($from: XL) {
      padding-right: calc(12px * var(--item-index));
      border-bottom-color: $white;
    }
  }

  &__list-item-link {
    @include menu-link();

    &,
    &:hover,
    &:active {
      color: var(--theme-color-contrast);
    }

    &:focus {
      outline: none;
    }

    @include mq($from: XL) {
      padding-left: 25px;
      padding-right: 25px;

      &,
      &:hover,
      &:active {
        color: $white;
      }

      &:hover,
      &:active {
        background: rgba($white, 0.05);
      }
    }

    &[aria-expanded="true"] {
      color: var(--theme-color-contrast);
      background: var(--theme-color);
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 5px;
    }
  }

  &__list-item-icon {
    @include menu-icon;
    pointer-events: none;

    @include mq($from: XL) {
      fill: var(--theme-color);
    }

    [aria-expanded="true"] > & {
      fill: currentColor;
    }
  }
}

.primary-navigation-subnav {
  visibility: hidden;
  opacity: 0;
  transition: visibility $transition-speed * 2 $transition-timing,
    opacity $transition-speed * 2 $transition-timing,
    transform $transition-speed * 2 $transition-timing;

  &.active {
    visibility: visible;
    opacity: 1;
  }

  @include mq($until: XL) {
    @include flyout;

    transform: translateX(100%);

    &.active {
      transform: translateX(0);
    }
  }

  @include mq($from: XL) {
    position: absolute;
    top: 0;
    left: calc(100% + 1px);
    height: 100%;
    width: 325px;
    padding: 130px 20px;
    z-index: -1;
    background: var(--theme-color);
    transform: skew($default-skew);
    transform-origin: 0 0;
    display: flex;
    align-items: flex-start;

    &.active {
      transform: skew($default-skew);
    }
  }

  &__inner {
    border-top: 1px solid var(--theme-color-contrast);
    flex: 1;

    @include mq($until: XL) {
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 20px 70px;
    }
  }

  &__btn-back {
    position: absolute;
    left: 20px;
    top: 15px;
    padding: 0;
    border: 0;
    color: var(--theme-color-contrast);
    display: flex;
    align-items: center;
    height: 40px;

    &,
    &:hover,
    &:active,
    &:focus {
      background: transparent;
    }

    svg {
      @include menu-icon();
      margin-left: 0;
      margin-right: 5px;
      transform: rotate(180deg);
    }

    @include mq($from: XL) {
      display: none;
    }
  }

  &__item {
    border-bottom: 1px solid var(--theme-color-contrast);

    @include mq($from: XL) {
      transform: skew(-$default-skew);
    }
  }

  &__item-link {
    @include menu-link();

    &,
    &:hover,
    &:active {
      color: var(--theme-color-contrast);
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 5px;
    }
  }

  &__item-icon {
    @include menu-icon;
  }
}

.primary-navigation-toggle {
  height: 40px;
  width: 36px;
  position: relative;
  z-index: 1002;
  transform: skew($default-skew);
  transition: all $transition-speed $transition-timing;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  padding: 0;

  &:hover,
  &:focus,
  &:active {
    background: transparent;
  }

  @include mq($from: XL) {
    display: none;
  }

  .meat {
    margin: 8px 0;
    position: relative;
    transition: all $transition-speed * 2 $transition-timing;

    &,
    &::before,
    &::after {
      background-color: $white;
      border-radius: 0;
      height: 2px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      transition: all $transition-speed $transition-timing;
      width: 20px;
      left: 0;
    }

    &,
    &::before {
      width: 20px;
    }

    &::before {
      top: -8px;
    }

    &::after {
      top: 8px;
      width: 10px;
    }
  }

  &.active {
    border-color: var(--theme-color-contrast);

    .meat {
      background-color: transparent;

      &::before,
      &::after {
        background-color: var(--theme-color-contrast);
      }

      &::before {
        transform: translateY(8px) rotate(45deg);
        width: 100%;
      }

      &::after {
        transform: translateY(-8px) rotate(-45deg);
        width: 100%;
      }
    }
  }
}

.primary-navigation-overlay {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba($color-default, 0.8);
  transition: visibility $transition-speed * 2 $transition-timing,
    opacity $transition-speed * 2 $transition-timing;

  &[hidden] {
    visibility: hidden;
    opacity: 0;
  }

  @include mq($from: XL) {
    position: absolute;
    height: 100vh;
    min-height: 730px;
    bottom: auto;
    z-index: 2;
  }
}

@import "../variables";
@import "../mixins";
@import "../mq";

.alert-bar {
  background: $color-big-bang;
  color: $white;
  display: none;

  @include mq($until: XL) {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 2;
  }

  &__inner {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.88889rem;
    padding-bottom: $space/2;
    padding-top: $space/2;
  }

  &__text {
    flex: 1;
    padding-left: $space * 5;

    p {
      margin: 0;
    }

    a {
      color: $white;

      &:hover {
        text-decoration: none;
      }
    }

    @include mq($from: XL) {
      padding-left: 0;
    }
  }

  &__close {
    cursor: pointer;
    display: block;
    height: $space;
    margin-left: $space;
    min-height: 0;
    position: relative;
    width: $space;

    span {
      @include no-text;
    }

    &::before,
    &::after {
      background-color: $white;
      content: "";
      height: 3px;
      left: 0;
      position: absolute;
      top: 8px;
      transition: background-color $transition-speed $transition-timing;
      width: $space;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }

    &:hover {
      &::before,
      &::after {
        background-color: $primary-highlight-color;
      }
    }
  }

  &--visible {
    display: block;
  }
}

@import "../../variables";
@import "../../mixins";
@import "../../mq";

.image-block {
  margin-top: $space * 1.5;
  margin-bottom: $space * 2.5;
  position: relative;
  height: 0;
  padding: 0 0 percentage(240 / 375);

  @include mq($from: M) {
    margin-top: $space * 2.5;
    margin-bottom: $space * 3.5;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -$space * 1.5;
    bottom: -$space * 1.5;
    left: 5%;
    right: 5%;
    z-index: 1;
    margin: 0 5%;
    transform: skew($default-skew);
    border: 8px solid var(--theme-color);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 30%;
    z-index: 2;
    background: var(--theme-pattern-white) 100% 75% repeat-x;
    background-size: 5rem;

    @include mq($from: M) {
      right: 30%;
    }
  }

  .panel--inverted & {
    &::before {
      border-color: var(--theme-color-contrast);
    }
  }

  .content-panel__row--one-column & {
    position: relative;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    height: 0;
    margin-bottom: $space * 1.5;

    &::before {
      @include fluid-type(
        border-width,
        $minScreen,
        $maxScreen,
        0.5rem,
        0.75rem
      );
    }

    &::after {
      @include fluid-type(
        background-size,
        $minScreen,
        $maxScreen,
        5rem,
        7.5rem
      );
    }

    @include mq($from: M) {
      padding-bottom: calc((500 / 1440) * 100vw);
      margin-bottom: $space * 2.5;

      &::before {
        top: -$space * 2.5;
        bottom: -$space * 2.5;
        margin: 0 #{"min(100vw * (2 / 12), 166px)"};
      }

      &::after {
        right: 60%;
      }
    }
  }

  &__image {
    position: absolute;
    left: 0;
    margin: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;

    .image-block--no-crop & {
      height: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &--no-frame {
    margin-bottom: $baseline;
    margin-top: 0;

    @include mq($from: M) {
      margin-bottom: 0;
      margin-top: 0;
    }

    &::before,
    &::after {
      content: none;
    }
  }
}

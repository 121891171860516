// @import "../../variables";
// @import "../../mixins";
@import "../../mq";

.map-block {
  &__map {
    height: 300px;
    width: 100%;

    @include mq($from: M) {
      height: 400px;
    }

    @include mq($from: L) {
      height: 600px;
    }
  }
}

@import "../variables";
// @import "../mixins";
@import "../mq";

.isotope-grid {
  $comp: &;

  display: flex;
  flex-wrap: wrap;
  opacity: 1;
  transition: all $transition-speed $transition-timing;

  &--unloaded {
    background: url("/img/dist/loader.gif") center no-repeat;
    background-size: 64px 64px;
    height: 128px !important;
    pointer-events: none;
    position: relative;
  }

  &__item {
    display: block;
    margin-bottom: $space;
    width: 100%;

    @include mq($from: S) {
      width: calc(50% - 10px);
    }

    @include mq($from: L) {
      width: calc(33.33% - 14px);
    }

    > a {
      display: flex;
      flex-direction: column;
      height: 100%;
      transition: box-shadow $transition-speed $transition-timing;

      &:hover {
        box-shadow: 0 0 10px 2px rgba($black, 0.25);
      }
    }

    &--linked {
      border: 1px solid $color-default;
      text-decoration: none;

      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        z-index: -1;
        transition: width $transition-speed $transition-timing,
          height $transition-speed $transition-timing;
      }

      &:before {
        top: -1px;
        bottom: -1px;
        right: calc(100% + 1px);
        width: 0;
        transform: skewY(-45deg);
        background: $color-default;
        transform-origin: right top;
        will-change: width;
      }

      &:after {
        left: -1px;
        right: -1px;
        top: calc(100% + 1px);
        height: 0;
        transform: skewX(-45deg);
        background: $color-default;
        transform-origin: left top;
        will-change: height;
      }

      &:hover,
      &:active {
        &:before {
          width: 10px;
        }

        &:after {
          height: 10px;
        }
      }
    }

    #{$comp}--unloaded & {
      opacity: 0;
    }
  }

  &__no-results {
    display: none;
    padding: $space 0;

    p {
      font-weight: 700;
    }

    &.active {
      display: block;
    }
  }
}

@import "../../variables";
// @import "../../mixins";
@import "../../mq";

.text-block {
  > *:last-child {
    @include mq($from: M) {
      margin-bottom: 0;

      .panel--no-bottom-padding & {
        margin-bottom: $baseline;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $color-default;
    -webkit-text-fill-color: unset;
    -webkit-text-stroke-width: 0;

    .theme--digital .panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      color: $white;
    }

    .panel--dark & {
      color: $white;
    }
  }

  p > a:not(.btn) {
    &,
    span {
      color: $white;
      background-color: var(--theme-color);
      -webkit-text-fill-color: unset;
      -webkit-text-stroke-width: 0;
      border-left: 4px solid var(--theme-color);
      border-right: 4px solid var(--theme-color);

      .theme--competition &,
      .panel--theme-competition &,
      .theme--at-school &,
      .panel--theme-at-school &,
      .theme--fair &,
      .panel--theme-fair & {
        color: $color-default;
      }

      .panel--inverted & {
        background-color: $color-default;
        border-color: $color-default;
        color: $white;
      }

      .theme--digital
        .panel:not(.panel--theme-competition):not(.panel--theme-at-school):not(.panel--theme-fair):not(.panel--inverted)
        &,
      .panel--theme-digital & {
        color: $white;
      }

      .theme--digital .panel--inverted &,
      .panel--theme-digital.panel--inverted & {
        background-color: $white;
        border-color: $white;
        color: var(--theme-color);
      }

      .theme--competition .panel--inverted &,
      .panel--theme-competition.panel--inverted &,
      .theme--at-school .panel--inverted &,
      .panel--theme-at-school.panel--inverted &,
      .theme--fair .panel--inverted &,
      .panel--theme-fair.panel--inverted & {
        color: var(--theme-color);
      }
    }
  }

  a.btn,
  span.btn > a,
  a > span.btn {
    color: var(--theme-color-contrast);
    background-color: transparent;

    &::before {
      background-color: var(--theme-color);
    }

    .panel--dark &,
    .panel--dark.panel--inverted & {
      color: var(--theme-color-contrast);

      &::before {
        background-color: var(--theme-color);
      }

      &::after {
        background-image: url("/img/dist/sprite/arrow-white.svg");
      }
    }

    .panel--inverted & {
      color: var(--theme-color-background);

      &::before {
        background-color: var(--theme-color-foreground);
      }

      &::after {
        background-image: url("/img/dist/sprite/arrow.svg");
      }
    }

    .theme--competition
      .panel:not(.panel--theme-digital):not(.panel--inverted)
      &,
    .theme--at-school .panel:not(.panel--theme-digital):not(.panel--inverted) &,
    .theme--fair .panel:not(.panel--theme-digital):not(.panel--inverted) &,
    .panel--theme-competition &,
    .panel--theme-at-school &,
    .panel--theme-fair & {
      &::after {
        background-image: url("/img/dist/sprite/arrow.svg");
      }
    }

    .theme--digital
      .panel:not(.panel--theme-competition):not(.panel--theme-at-school):not(.panel--theme-fair):not(.panel--inverted)
      &
      .panel--theme-digital
      & {
      &::after {
        background-image: url("/img/dist/sprite/arrow-white.svg");
      }
    }

    .theme--default .panel--inverted &,
    .theme--big-bang .panel--inverted &,
    .panel--theme-default.panel--inverted &,
    .panel--theme-big-bang.panel--inverted & {
      color: $white;

      &::before {
        background-color: $color-default;
      }

      &::after {
        background-image: url("/img/dist/sprite/arrow-white.svg");
      }
    }

    .theme--competition .panel--inverted &,
    .panel--theme-competition.panel--inverted & {
      color: $color-competition;

      &::before {
        background-color: $color-default;
      }

      &::after {
        background-image: url("/img/dist/sprite/arrow-competition.svg");
      }
    }

    .theme--digital .panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      color: $color-digital;

      &::before {
        background-color: $white;
      }

      &::after {
        background-image: url("/img/dist/sprite/arrow-digital.svg");
      }
    }

    .theme--at-school .panel--inverted &,
    .panel--theme-at-school.panel--inverted & {
      color: $color-at-school;

      &::after {
        background-image: url("/img/dist/sprite/arrow-at-school.svg");
      }
    }

    .theme--fair .panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      color: $color-fair;

      &::after {
        background-image: url("/img/dist/sprite/arrow-fair.svg");
      }
    }
  }

  span.btn {
    @include mq($from: M, $until: L) {
      transform: translateX(-10px);
    }

    .panel--halign-right & {
      @include mq($from: L) {
        transform: translateX(-55px);
      }
    }
  }

  a.btn,
  a > span.btn {
    transform: translateX(30px);

    @include mq($from: M) {
      transform: translateX(35px);
    }

    .panel--halign-right & {
      @include mq($from: L) {
        transform: translateX(-35px);
      }
    }
  }

  hr {
    .theme--default .panel--inverted &,
    .panel--dark & {
      background-color: $white;
    }

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        background-color: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        background-color: $color-default;
      }
    }
  }

  ul {
    list-style-type: none;
    margin: 0 0 $baseline;
    padding: 0;

    li {
      margin-bottom: $space/2;
      padding-left: 25px;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        background-color: $color-default;
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 8px;
        left: 0;

        .theme--default .panel--inverted &,
        .panel--dark & {
          background-color: $white;
        }

        .theme--big-bang .panel--inverted &,
        .theme--digital .panel--inverted &,
        .panel--theme-big-bang.panel--inverted &,
        .panel--theme-digital.panel--inverted & {
          &,
          body:not(.theme--big-bang):not(.theme--digital) & {
            background-color: $white;
          }
        }

        .theme--competition .panel--inverted &,
        .theme--at-school .panel--inverted &,
        .theme--fair .panel--inverted &,
        .panel--theme-competition.panel--inverted &,
        .panel--theme-at-school.panel--inverted &,
        .panel--theme-fair.panel--inverted & {
          &,
          body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
            & {
            background-color: $color-default;
          }
        }
      }

      ul,
      ol {
        padding-top: $space/2;
      }
    }
  }

  ol {
    margin: 0 0 40px $space * 2;
    padding: 0;

    li {
      margin-bottom: $space/2;
      position: relative;

      ul,
      ol {
        padding-top: $space/2;
      }
    }
  }

  table {
    td,
    th {
      .theme--default .panel--inverted &,
      .panel--dark & {
        border-bottom: 1px solid $white;
      }

      .theme--big-bang .panel--inverted &,
      .theme--digital .panel--inverted &,
      .panel--theme-big-bang.panel--inverted &,
      .panel--theme-digital.panel--inverted & {
        &,
        body:not(.theme--big-bang):not(.theme--digital) & {
          border-bottom: 1px solid $white;
        }
      }

      .theme--competition .panel--inverted &,
      .theme--at-school .panel--inverted &,
      .theme--fair .panel--inverted &,
      .panel--theme-competition.panel--inverted &,
      .panel--theme-at-school.panel--inverted &,
      .panel--theme-fair.panel--inverted & {
        &,
        body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
          & {
          border-bottom: 1px solid $color-default;
        }
      }

      &::after {
        .theme--default .panel--inverted &,
        .panel--dark & {
          background-color: $white;
        }

        .theme--big-bang .panel--inverted &,
        .theme--digital .panel--inverted &,
        .panel--theme-big-bang.panel--inverted &,
        .panel--theme-digital.panel--inverted & {
          &,
          body:not(.theme--big-bang):not(.theme--digital) & {
            background-color: $white;
          }
        }

        .theme--competition .panel--inverted &,
        .theme--at-school .panel--inverted &,
        .theme--fair .panel--inverted &,
        .panel--theme-competition.panel--inverted &,
        .panel--theme-at-school.panel--inverted &,
        .panel--theme-fair.panel--inverted & {
          &,
          body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
            & {
            background-color: $color-default;
          }
        }
      }
    }
  }

  thead,
  tbody:first-child tr:first-child {
    th {
      .theme--default .panel--inverted &,
      .panel--dark & {
        background-color: rgba($white, 0.15);
      }

      .theme--big-bang .panel--inverted &,
      .theme--digital .panel--inverted &,
      .panel--theme-big-bang.panel--inverted &,
      .panel--theme-digital.panel--inverted & {
        &,
        body:not(.theme--big-bang):not(.theme--digital) & {
          background-color: rgba($white, 0.15);
        }
      }

      .theme--competition .panel--inverted &,
      .theme--at-school .panel--inverted &,
      .theme--fair .panel--inverted &,
      .panel--theme-competition.panel--inverted &,
      .panel--theme-at-school.panel--inverted &,
      .panel--theme-fair.panel--inverted & {
        &,
        body:not(.theme--competition):not(.theme--at-school):not(.theme--fair)
          & {
          background-color: rgba($color-default, 0.15);
        }
      }
    }
  }

  .panel--has-background & {
    @include mq($from: L) {
      background: rgba(255, 255, 255, 0.7);
      padding: ($space * 2);
    }
  }
}

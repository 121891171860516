@import "../../variables";
@import "../../mixins";
@import "../../mq";

.team-block {
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -$space;

    .content-panel__row--two-column & {
      display: block;
    }
  }

  &__item {
    background-color: $white;
    border: 1px solid $color-default;
    display: flex;
    flex-direction: column;
    margin-bottom: $space;
    width: 100%;

    @include mq($from: M) {
      margin-left: $space;
      width: calc(50% - #{$space/2});

      &:nth-child(odd) {
        margin-left: 0;
      }
    }

    @include mq($from: L) {
      width: calc(33% - 12px);

      &:nth-child(odd) {
        margin-left: $space;
      }

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }
  }

  &__image-container {
    border-bottom: 1px solid $color-default;
    position: relative;
    height: 0;
    padding-bottom: percentage(300/378);
  }

  &__image {
    @include object-fit(cover, center);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    -ms-flex: auto;
    padding: $space;

    .theme--competition .panel:not(.panel--dark) &,
    .theme--at-school .panel:not(.panel--dark) &,
    .theme--fair .panel:not(.panel--dark) &,
    .panel--theme-competition:not(.panel--dark) &,
    .panel--theme-at-school:not(.panel--dark) &,
    .panel--theme-fair:not(.panel--dark) & {
      background-color: $color-default;
    }
  }

  &__name {
    @include headingFont($h4-sm, $h4-lg);
    color: var(--theme-color);
    line-height: 1.15;
    margin: 0 0 ($space/2);
    -webkit-text-fill-color: currentColor;
    -webkit-text-stroke-width: 0;
  }

  &__desc {
    color: $color-default;
    font-size: $minFont;

    .theme--competition .panel:not(.panel--dark) &,
    .theme--at-school .panel:not(.panel--dark) &,
    .theme--fair .panel:not(.panel--dark) &,
    .panel--theme-competition:not(.panel--dark) &,
    .panel--theme-at-school:not(.panel--dark) &,
    .panel--theme-fair:not(.panel--dark) & {
      color: $white;
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    margin-top: auto;
  }

  &__action {
    background-color: var(--theme-color-background);
    color: var(--theme-color-foreground);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 0.5rem;
    transform: skew($default-skew);
    font-size: 0.9rem;
    margin-right: 1rem;
    text-decoration: none;
    transition: transform $transition-speed $transition-timing;

    span {
      display: block;
      margin-left: 0.25rem;
      transform: translateY(1px);
    }

    &:hover {
      transform: skew(0);
      color: var(--theme-color-foreground);
    }

    @include mq($from: XL) {
      height: 40px;
      font-size: 1.2rem;
    }
  }

  &__link {
    &,
    &:hover {
      color: var(--theme-color);
    }
  }

  &__icon {
    fill: transparent;
    position: relative;
    stroke: currentColor;
    width: 1em;
    height: 1em;

    &--email {
      fill: currentColor;
    }

    &--linkedin {
      stroke-width: 2px;
    }
  }
}

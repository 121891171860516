@import "../../variables";
@import "../../mixins";
@import "../../mq";

.stats-block {
  margin-bottom: -$space * 2;
  text-align: center;

  &__items {
    list-style: none;

    @include mq($from: S) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      margin-bottom: $space * 2;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }

      @include mq($from: S) {
        width: 50%;

        &:last-child {
          margin-bottom: $space * 2;
        }
      }

      @include mq($from: M) {
        width: 33.3333%;
      }

      @include mq($from: L) {
        width: 25%;

        .content-panel__row--two-column & {
          width: 33%;
        }
      }
    }
  }

  &__content {
    color: $default-text-color;
    padding: 0 $space;

    p {
      font-size: 0.8rem;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .theme--default .panel--inverted &,
    .panel--dark & {
      color: $white;
    }

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        color: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        color: $color-default;
      }
    }
  }

  &__statistic {
    @include headingFont($h4-sm, $h4-lg);
    color: $color-default;
    letter-spacing: normal;
    line-height: 1.15;
    margin: 0 0 $baseline/3;
    -webkit-text-fill-color: $color-default;
    -webkit-text-stroke-width: 0;

    .theme--default .panel--inverted &,
    .panel--dark & {
      color: $white;
      -webkit-text-fill-color: $white;
    }

    .theme--big-bang .panel--inverted &,
    .theme--digital .panel--inverted &,
    .panel--theme-big-bang.panel--inverted &,
    .panel--theme-digital.panel--inverted & {
      &,
      body:not(.theme--big-bang):not(.theme--digital) & {
        color: $white;
        -webkit-text-fill-color: $white;
      }
    }

    .theme--competition .panel--inverted &,
    .theme--at-school .panel--inverted &,
    .theme--fair .panel--inverted &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &,
      body:not(.theme--competition):not(.theme--at-school):not(.theme--fair) & {
        color: $color-default;
        -webkit-text-fill-color: $color-default;
      }
    }
  }
}

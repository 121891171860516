@import "../../variables";
@import "../../mixins";
@import "../../mq";

.image-grid-block {
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    position: relative;

    @include fluid-type(
      margin-bottom,
      $minScreen,
      $maxScreen,
      -1.25rem,
      -2.5rem
    );

    @include fluid-type(
      margin-left margin-right,
      $minScreen,
      $maxScreen,
      -0.625rem,
      -1.25rem
    );

    &--as-carousel {
      display: block;
      margin: 0;

      @include mq($from: L) {
        .slick-list {
          @include fluid-type(
            margin-left margin-right,
            $minScreen,
            $maxScreen,
            -0.625rem,
            -1.25rem
          );
        }
      }
    }
  }

  &__image-container {
    margin-bottom: $space;
    position: relative;
    width: 100%;
    padding-left: $space / 2;
    padding-right: $space / 2;

    @include mq($from: S) {
      width: 50%;
    }

    picture {
      overflow: hidden;
      display: block;
      position: relative;
      height: 0;
      padding-bottom: percentage(240/290);
    }

    .image-grid-block__items--as-carousel & {
      margin-bottom: 0;
      width: auto;
    }
  }

  &__image-name {
    @include fluid-type(font-size, $minScreen, $maxScreen, $h2-sm, 2.25rem);
    @include fluid-type(left right, $minScreen, $maxScreen, 0.625rem, 1.25rem);
    position: absolute;
    bottom: 0;
    z-index: 2;
    background: rgba($primary-highlight-color, 0.8);
    color: $white;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    padding: 5% 1rem;
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin: 0;
    min-height: $space * 10;
    padding: 0;
    transition: transform $transition-speed * 3 $transition-timing;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    a:hover & {
      transform: scale(1.1);
    }
  }
}

@import "../variables";
// @import "../mixins";
@import "../mq";

.primary-search {
  position: absolute;
  right: 90px;
  top: 0;

  @include mq($from: L) {
    position: relative;
    right: auto;
    top: auto;
  }

  &__button {
    background: transparent;
    border: 0;
    padding: 18px 0 0;

    @include mq($from: L) {
      margin-left: 1rem;
      padding-bottom: 8px;
      padding-top: 8px;
    }

    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: $primary-highlight-color;
    }
  }

  &__modal {
    background: rgba($secondary-highlight-color, 0.9);
    height: 100%;
    left: 0;
    opacity: 0;
    padding: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: ease-in-out opacity $transition-speed;
    width: 100%;
    z-index: 99999;

    &.is-visible {
      opacity: 1;
      pointer-events: all;
    }

    form {
      display: flex;
    }

    .constrain {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
    }
  }

  &__input {
    @extend %field;
  }

  &__modal-button {
    span {
      position: absolute;
      top: -9999px;
    }
  }

  &__close {
    background: transparent;
    color: $white;
    font-size: 2.4rem;
    border: 0;
    position: absolute;
    right: 0;
    top: 25px;

    &:hover,
    &:focus,
    &:active {
      background: transparent;
      color: $primary-highlight-color;
    }
  }

  &__icon {
    fill: $secondary-highlight-color;
    width: 50px;
    height: 50px;

    @include mq($from: L) {
      width: 30px;
      height: 26px;
    }

    button:hover &,
    button:focus &,
    button:active & {
      fill: $primary-highlight-color;
    }
  }
}

@import "../../variables";
// @import "../../mixins";
@import "../../mq";

.video-block {
  &__container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    @include mq($until: M) {
      margin-bottom: $baseline;
    }

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  .content-panel--has-heading & {
    &::before,
    &::after {
      margin-top: 27px;

      @include mq($from: S) {
        margin-top: 36px;
      }

      @include mq($from: L) {
        margin-top: 62px;
      }
    }
  }

  .theme--default .panel--inverted &,
  .theme--big-bang .panel--inverted &,
  .panel--theme-big-bang.panel--inverted &,
  .panel--dark & {
    &::before {
      background: url("/img/dist/sprite/shape-lines-white.svg") center repeat-x;
      content: "";
      display: block;
      height: 76px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;

      @include mq($until: S) {
        background-size: auto 38px;
        height: 38px;
      }
    }
  }

  .theme--competition .panel--inverted &,
  .panel--theme-competition.panel--inverted & {
    &,
    body:not(.theme--competition) & {
      &::before {
        background-image: url("/img/dist/sprite/shape-jagged-default.svg");
        height: 162px;

        @include mq($until: S) {
          background-size: auto 81px;
          height: 81px;
        }
      }
    }
  }

  .theme--digital .panel--inverted &,
  .theme--at-school .panel--inverted &,
  .panel--theme-digital.panel--inverted &,
  .panel--theme-at-school.panel--inverted & {
    &,
    body:not(.theme--digital):not(.theme--at-school) & {
      &::before {
        background-image: url("/img/dist/sprite/shape-waves-white.svg");
        height: 145px;

        @include mq($until: S) {
          background-size: auto 72px;
          height: 72px;
        }
      }
    }
  }

  .theme--fair .panel--inverted &,
  .panel--theme-fair.panel--inverted & {
    &,
    body:not(.theme--fair) & {
      &::before {
        background-image: url("/img/dist/sprite/shape-jagged-white.svg");
        height: 162px;

        @include mq($until: S) {
          background-size: auto 81px;
          height: 81px;
        }
      }
    }
  }
}

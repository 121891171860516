@import "variables";
// @import "mixins";
@import "mq";

// Slick overrides
.slick-track {
  display: flex;
}

.slick-list {
  @include mq($until: XL) {
    margin: 0 (calc(-5vw));
    padding: 0 calc(5vw - 10px);
  }
}

.slick-slide {
  float: none;
  height: auto;

  > div {
    display: flex;
    flex: 1;
  }
}

.slick-initialized .slick-slide {
  display: flex;
}

.slick-next,
.slick-prev {
  color: var(--theme-color-foreground);
  background-color: var(--theme-color-background);
  transform: skew($default-skew);
  width: 36px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform $transition-speed $transition-timing;

  &:hover,
  &:focus,
  &:active {
    color: var(--theme-color-foreground);
    background-color: var(--theme-color-background);
    transform: skew(0);
  }

  .panel--inverted & {
    color: var(--theme-color-foreground-contrast);
    background-color: var(--theme-color-background-contrast);

    &:hover,
    &:focus,
    &:active {
      color: var(--theme-color-foreground-contrast);
      background-color: var(--theme-color-background-contrast);
    }
  }

  &::before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    color: var(--theme-color-foreground);
    border: solid currentColor;
    border-width: 0 2px 2px 0;
    transition: transform $transition-speed $transition-timing;
    opacity: 1;

    .panel--inverted & {
      color: var(--theme-color-foreground-contrast);
    }
  }

  .panel--dark & {
    color: $white;

    &:hover,
    &:active {
      color: var(--theme-color);
    }
  }

  @include mq($until: XL) {
    top: auto;
    bottom: -$space * 2;
    right: 0;
    left: auto;
    width: 26px;
    height: 30px;
  }
}

.slick-prev {
  right: 26px + 10px;

  &::before {
    transform: rotate(45deg * 3) skew(-$default-skew);
    margin-left: 4px;
  }

  @include mq($from: XL) {
    left: -40px;
    right: auto;
  }

  @include mq($from: XXL) {
    left: -50px;
  }
}

.slick-next {
  &::before {
    transform: rotate(-45deg) skew(-$default-skew);
    margin-left: -4px;
  }

  @include mq($from: XL) {
    right: -40px;
  }

  @include mq($from: XXL) {
    right: -50px;
  }
}

.slick-dots {
  display: flex;
  justify-content: flex-start;
  bottom: -$space * 1.5;
  width: calc(100% - 100px);

  li {
    margin: 0 5px;
    height: auto;
    width: auto;
  }

  li button {
    background: transparent;
    color: var(--theme-color-background);
    border: 2px solid currentColor;
    opacity: 1;
    width: 28px;
    height: 8px;
    overflow: hidden;
    text-indent: 100%;
    font-size: 0;
    min-height: 0;
    padding: 0;
    transform: skew($default-skew);
    transition: color $transition-speed $transition-timing;

    &::before {
      content: none;
    }

    &[aria-selected="true"] {
      background: currentColor;
    }

    &:hover,
    &:active {
      &:not([aria-selected="true"]) {
        color: var(--theme-color-background);
      }
    }
  }

  @include mq($from: XL) {
    justify-content: center;
    width: 100%;

    li {
      margin: 0 10px;
    }

    li button {
      width: 60px;
      height: 10px;
    }
  }

  .panel--inverted & button {
    color: var(--theme-color-background-contrast);

    &:hover,
    &:active {
      &:not([aria-selected="true"]) {
        color: var(--theme-color-background-contrast);
      }
    }
  }

  .panel--dark & button {
    color: var(--theme-color-background);

    &:hover,
    &:active {
      &:not([aria-selected="true"]) {
        color: var(--theme-color-background);
      }
    }
  }
}

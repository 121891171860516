@import "../variables";
@import "../mixins";
@import "../mq";

.event-page {
  .panel--inverted,
  .panel--dark {
    &:last-child {
      margin-bottom: $baseline;

      @include mq($from: M) {
        margin-bottom: $baseline * 4;
      }
    }
  }
}

.event {
  &__dates-list {
    margin-bottom: -1px;
    position: relative;
    z-index: 1;
  }

  &__dates-list ul {
    font-weight: 700;

    @include mq($from: M) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__dates-list-item {
    border: 1px solid $color-default;
    color: $color-default;
    cursor: pointer;
    font-family: $heading-font;
    margin-bottom: -1px;
    padding: $space/2 $space;
    position: relative;
    text-transform: uppercase;

    &:first-child {
      margin-left: 0;
    }

    .theme--big-bang & {
      border-color: $color-big-bang;
      color: $color-big-bang;
    }

    .theme--competition & {
      border-color: $color-competition;
    }

    .theme--digital & {
      border-color: $color-digital;
      color: $color-digital;
    }

    .theme--at-school & {
      border-color: $color-at-school;
    }

    .theme--fair & {
      border-color: $color-fair;
    }

    @include mq($from: M) {
      background-color: $color-default;
      color: $white;
      margin-bottom: 0;
      margin-left: $space/2;
      opacity: 1;

      .theme--big-bang & {
        border-color: $color-big-bang;
        background-color: $color-big-bang;
        color: $white;
      }

      .theme--competition & {
        border-color: $color-competition;
        background-color: $color-competition;
        color: $color-default;
      }

      .theme--digital & {
        border-color: $color-digital;
        background-color: $color-digital;
        color: $white;
      }

      .theme--at-school & {
        border-color: $color-at-school;
        background-color: $color-at-school;
        color: $color-default;
      }

      .theme--fair & {
        border-color: $color-fair;
        background-color: $color-fair;
        color: $color-default;
      }
    }

    &.active {
      background-color: $color-default;
      border-bottom-color: $color-default;
      color: $white;
      opacity: 1;

      .theme--big-bang & {
        background-color: $color-big-bang;
        border-bottom-color: $color-big-bang;
      }

      .theme--competition & {
        background-color: $color-competition;
        border-bottom-color: $color-competition;
        color: $color-default;
      }

      .theme--digital & {
        background-color: $color-digital;
        border-bottom-color: $color-digital;
      }

      .theme--at-school & {
        background-color: $color-at-school;
        border-bottom-color: $color-at-school;
        color: $color-default;
      }

      .theme--fair & {
        background-color: $color-fair;
        border-bottom-color: $color-fair;
        color: $color-default;
      }

      @include mq($from: M) {
        background-color: $white;
        border-bottom-color: $white;
        color: $color-default;

        .theme--big-bang &,
        .theme--competition &,
        .theme--digital &,
        .theme--at-school &,
        .theme--fair & {
          background-color: $white;
          border-bottom-color: $white;
        }

        .theme--big-bang & {
          color: $color-big-bang;
        }

        .theme--digital & {
          color: $color-digital;
        }
      }

      &::before,
      &::after {
        background-color: $color-default;
        bottom: -1px;
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        width: 1px;

        .theme--big-bang & {
          background-color: $color-big-bang;
        }

        .theme--competition & {
          background-color: $color-competition;
        }

        .theme--digital & {
          background-color: $color-digital;
        }

        .theme--at-school & {
          background-color: $color-at-school;
        }

        .theme--fair & {
          background-color: $color-fair;
        }
      }

      &::before {
        left: -1px;
      }

      &::after {
        right: -1px;
      }
    }
  }

  &__dates {
    margin-bottom: $baseline;

    @include mq($from: M) {
      margin-bottom: $baseline * 4;
    }
  }

  &__date {
    border: 1px solid $color-default;
    display: none;
    padding: $space;

    @include mq($from: M) {
      padding: $baseline * 2;
    }

    &.active {
      display: block;
    }

    &--two-column {
      flex-direction: column;
      flex-wrap: wrap;

      &.active {
        display: flex;
      }

      @include mq($from: M) {
        flex-direction: row;
      }
    }

    .theme--big-bang & {
      border-color: $color-big-bang;
    }

    .theme--competition & {
      border-color: $color-competition;
    }

    .theme--digital & {
      border-color: $color-digital;
    }

    .theme--at-school & {
      border-color: $color-at-school;
    }

    .theme--fair & {
      border-color: $color-fair;
    }
  }

  &__heading {
    margin: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.session {
  &__header {
    @include mq($from: M) {
      align-items: flex-start;
      display: flex;
    }
  }

  &__time {
    background-color: $color-default;
    color: $white;
    display: inline-block;
    padding: 2px 6px;

    @include mq($until: M) {
      margin-bottom: $baseline/2;
    }

    .theme--big-bang & {
      background-color: $color-big-bang;
    }

    .theme--competition & {
      background-color: $color-competition;
      color: $color-default;
    }

    .theme--digital & {
      background-color: $color-digital;
    }

    .theme--at-school & {
      background-color: $color-at-school;
      color: $color-default;
    }

    .theme--fair & {
      background-color: $color-fair;
      color: $color-default;
    }
  }

  &__heading {
    margin: 0;

    @include mq($from: M) {
      margin-left: 0.5rem;
    }

    .theme--big-bang & {
      color: $color-big-bang;
    }

    .theme--digital & {
      color: $color-digital;
    }
  }

  &__group {
    padding-top: $baseline;
    position: relative;

    @include mq($from: M) {
      padding-top: $baseline * 2;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    background-color: $color-default;
    display: block;
    height: 200px;
    overflow: hidden;
    position: relative;
    transition: background-color $transition-speed * 3 $transition-timing;

    &:hover {
      background-color: darken($color-default, 10%);
    }
  }

  &__name {
    bottom: $space;
    color: $white;
    font-size: 1rem;
    font-weight: 700;
    left: $space;
    margin: 0;
    padding-right: $space;
    position: absolute;
    z-index: 1;
  }

  &__image-container {
    height: 100%;
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin: 0;
    min-height: $space * 10;
    opacity: 0.5;
    padding: 0;
    transition: all $transition-speed * 3 $transition-timing;
    width: 100%;

    a:hover & {
      opacity: 1;
      transform: scale(1.1);
    }
  }
}

.sponsors {
  margin-bottom: $baseline * 3;

  @include mq($from: M) {
    margin-bottom: $baseline * 4;
  }

  &__heading {
    @include mq($until: M) {
      margin: 0 0 $baseline/2;
    }
  }

  &__items {
    display: block;
    flex-wrap: wrap;
    position: relative;
    @include fluid-type(
      margin-bottom,
      $minScreen,
      $maxScreen,
      -1.25rem,
      -2.5rem
    );

    @include mq($from: XXL) {
      .slick-list {
        margin-left: -20px;
        margin-right: -20px;
      }
    }
  }

  &__logo-container {
    border: 1px solid $border-color;
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 240px;
    margin: 0 10px;

    @include mq($from: XXL) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &__logo {
    display: block;
    max-height: 80px;
    margin-bottom: 0;
    transition: transform $transition-speed $transition-timing;

    a:hover & {
      transform: scale(1.1);
    }
  }

  .slick-prev,
  .slick-next {
    transform: translateY(-50%) skew($default-skew);

    @include mq($until: L) {
      transform: translateY(5px) skew($default-skew);
    }
  }

  .slick-prev {
    @include mq($from: XXL) {
      left: -60px;
    }
  }

  .slick-next {
    @include mq($from: XXL) {
      right: -60px;
    }
  }

  .slick-dots {
    display: none !important;
  }
}

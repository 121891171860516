@import "../../variables";
@import "../../mixins";
@import "../../mq";

.quote-block {
  margin: 22px 0 0;
  text-align: center;
  position: relative;
  padding: 8px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: -1;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);
    background: 50% 50% repeat-x;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: $white;
    border: 8px solid var(--theme-color);
  }

  @include mq($from: M) {
    margin: 28px percentage(1/12) 0 percentage(1/12);
    padding: 12px;

    &::before {
      transform: translateX(-50%);
      background-image: var(--theme-pattern);
    }

    &::after {
      border-width: 12px;
      transform: skew($default-skew);
    }
  }

  .panel--inverted & {
    &::before {
      background-image: var(--theme-pattern-inverted);
    }

    &::after {
      border-color: var(--theme-color-contrast);
      background: var(--theme-color);
    }
  }

  .panel--dark &::after {
    border-color: var(--theme-color);
    background: $color-default;
  }

  .content-panel__row--two-column & {
    margin-left: 0;
    margin-right: 0;
    padding: 8px;

    &::before {
      background: none;
    }

    &::after {
      transform: none;
      border-width: 8px;
    }
  }

  &__quote-icon {
    display: block;
    fill: var(--theme-color);
    height: 30px;
    margin: -30px auto 0 auto;
    padding: 0 20px;
    background: $white;
    width: 80px;
    position: relative;
    z-index: 1;
    transform: skew($default-skew);

    @include mq($from: M) {
      height: 38px;
      width: 100px;
      padding: 0 25px;
      margin-top: -38px;
    }

    &:last-child {
      transform: rotate(180deg);
    }

    .panel--inverted & {
      fill: var(--theme-color-contrast);
      background: var(--theme-color);
    }

    .panel--dark & {
      fill: var(--theme-color);
      background: $color-default;
    }
  }

  &__quote-container {
    margin: $space * 2 $space;
    position: relative;
    z-index: 1;

    @include mq($from: M) {
      margin: $space * 3 percentage(1/10);
    }

    .content-panel__row--two-column & {
      margin: $space * 2 $space;
      transform: none;
    }
  }

  &__quote,
  &__quote-link {
    @include headingFont($h4-sm, $h3-lg);
    color: $color-default;
    letter-spacing: normal;
    line-height: 1.125;
    margin-bottom: 0;
    -webkit-text-fill-color: $color-default;
    -webkit-text-stroke-width: 0;

    .content-panel__row--two-column & {
      font-size: $h4-sm;
    }

    .panel--inverted & {
      color: var(--theme-color-contrast);
      -webkit-text-fill-color: var(--theme-color-contrast);
    }
    .panel--dark & {
      color: $white;
      -webkit-text-fill-color: $white;
    }
  }

  &__quote-link {
    display: block;
    text-decoration: none;
    transition: opacity $transition-speed $transition-timing;

    &:hover {
      opacity: 0.5;
    }
  }

  &__name {
    color: $quote-cite-color;
    display: inline-block;
    font-weight: 400;
    margin-top: $baseline;

    .panel--inverted & {
      color: var(--theme-color-contrast);
    }

    .panel--dark & {
      color: $white;
    }
  }
}

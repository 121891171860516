@import "../variables";
@import "../mixins";
@import "../mq";

.grid {
  &__heading {
    @include headingFont($h4-sm, $h4-lg);
    color: $color-default;
    letter-spacing: normal;
    line-height: 1.15;
    margin: 0 0 $space;
    -webkit-text-fill-color: $color-default;
    -webkit-text-stroke-width: 0;

    a {
      text-decoration: none;
    }
  }

  &__items {
    @include mq($from: M) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -$space/2;
      width: calc(100% + #{$space});
    }

    &--is-carousel {
      .slick-list {
        padding-bottom: $space !important;
        padding-top: $space !important;
      }

      @include mq($until: S) {
        margin-left: -5%;
        width: 110%;
      }

      @include mq($until: 1480px) {
        margin-left: -3%;
        max-width: none;
        width: 104%;
      }
    }
  }

  &__item {
    background-color: $white;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    margin: $space/2 0;
    position: relative;
    width: 100%;

    @include mq($from: M) {
      margin: $space/2;
      width: calc(50% - #{$space});
    }

    @include mq($from: L) {
      width: calc(33.3333% - #{$space});
    }

    > a {
      display: flex;
      flex-direction: column;
      height: 100%;
      transition: box-shadow $transition-speed $transition-timing;

      &:hover {
        box-shadow: 0 0 10px 2px rgba($black, 0.25);
      }
    }

    .grid__items--is-carousel & {
      border: none;
      margin: 0;
      padding: 0 $space/4;
    }

    .article-feed-panel__featured & {
      display: block;
      margin: 0 auto;
      max-width: 1060px;
      width: auto;

      > a {
        display: flex;
        flex-direction: column;

        @include mq($from: M) {
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
    }
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
  }

  &__image-container {
    position: relative;
  }

  &__badge {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: $space;
    right: $space;
    border-radius: 50%;
    background-color: $color-competition;
    background-repeat: no-repeat;
    background-position: center;

    &--science {
      background-image: url("/img/dist/sprite/showcase-science-icon.svg");
    }
    &--engineering {
      background-image: url("/img/dist/sprite/showcase-engineering-icon.svg");
    }
    &--technology {
      background-image: url("/img/dist/sprite/showcase-technology-icon.svg");
      background-position: center;
      background-size: 60%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $space;
  }

  &__description {
    color: $default-text-color;
    font-size: 0.9rem;
    line-height: $baseline;
    overflow: hidden;
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.667rem;
    line-height: 1;
    list-style: none;
    margin-bottom: $baseline;

    li {
      background-color: var(--theme-color, $color-big-bang);
      margin-right: 4px;
      margin-bottom: 4px;
      padding: 4px;
    }
  }

  &__link-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: auto;

    .btn {
      margin-bottom: ($space/2);
      transform: translateX($space * 1.5);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

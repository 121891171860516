@import "../variables";
//@import "../mixins";
@import "../mq";

.social-share-list {
  $comp: &;

  display: flex;

  .social-share-heading + & {
    margin-left: 1rem;
  }

  &__item {
    margin-right: $space;

    a {
      align-items: center;
      display: flex;
      height: 100%;
    }
  }

  &__icon {
    fill: $default-link-color;
    position: relative;
    stroke: transparent;
    transition: fill $transition-speed $transition-timing;

    a:hover &,
    a:active & {
      fill: $color-big-bang;
    }

    &--facebook {
      height: 30px;
      width: 16px;
    }

    &--twitter {
      width: 25px;
      height: 24px;
    }

    &--instagram {
      width: 25px;
      height: 25px;
    }

    &--linkedin {
      width: 28px;
      height: 28px;
    }

    &--youtube {
      height: 22px;
      top: 1px;
      width: 28px;
    }
  }

  &--inverse {
    #{$comp}__icon {
      fill: var(--theme-color);
    }

    a:hover #{$comp}__icon,
    a:active #{$comp}__icon {
      fill: $white;
    }
  }
}

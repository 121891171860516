@import "../variables";
//@import "../mixins";
@import "../mq";

@mixin zigzag {
  background: url("/img/dist/zigzag-pop.svg") no-repeat;
  background-size: 100%;
  display: block;
  height: 1vw * (148/1440 * 100);
  width: 1vw * (441/1440 * 100);
  opacity: 0.1;

  @include mq($from: L) {
    content: "";
  }
}

//// Footer
.primary-footer {
  $comp: &;

  background: $footer-background;
  color: $footer-text-color;
  font-size: 0.8rem;
  overflow: hidden;
  position: relative;

  &::before {
    @include zigzag();

    left: -50px;
    top: 65%;
    position: absolute;
    transform: scaleY(-1);
  }

  &::after {
    background: url("/img/dist/sprite/lines.svg") no-repeat;
    background-size: 100%;
    display: block;
    height: 1vw * (470/1440 * 100);
    width: 1vw * (470/1440 * 100);
    opacity: 0.1;
    right: -1vw * (120/1440 * 100);
    top: -1vw * (50/1440 * 100);
    position: absolute;
    transform: rotate(45deg);

    @include mq($from: L) {
      content: "";
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &::before,
    &::after {
      @include zigzag();

      left: 30%;
      top: 5%;
      position: absolute;
    }

    &::after {
      left: 45%;
      top: 60%;
      transform: scaleX(-1);
    }

    @include mq($from: L) {
      flex-direction: row;
    }
  }

  &__column {
    flex: 1;
    position: relative;
    z-index: 1;
    border: 1px solid $white;
    border-top: 0;
    border-bottom: 0;
    padding: 0 $space;

    @include mq($from: L) {
      transform: skew($default-skew);
      margin-left: -1px;
      padding-top: $space * 2;
      padding-bottom: $space * 2;
    }
  }

  &__heading {
    @include headingFont($h4-sm, $h4-lg);

    color: var(--theme-color);
    padding: 22px $space;
    margin: 0 (-$space);

    -webkit-text-fill-color: currentColor;
    -webkit-text-stroke-width: 0;

    @include mq($from: L) {
      padding: 0;
      margin: 0 0 $baseline;
      transform: skew(-$default-skew);
    }
  }

  &__nav {
    margin-bottom: 0;

    @include mq($until: L) {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
      padding-bottom: 1px;

      li:last-child {
        margin-bottom: $space;
      }
    }

    @include mq($from: L) {
      max-height: none !important;

      li {
        border-top: 1px solid $white;
        border-bottom: 1px solid $white;
        margin: -1px (-$space) 0 (-$space);
      }
    }
  }

  &__accordion {
    @include mq($until: L) {
      margin: 0 (-$space);
      padding: 0 $space;
      border-bottom: 1px solid $white;
    }

    #{$comp}__heading {
      @include mq($until: L) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: color $transition-speed $transition-timing;

        &:hover,
        &:active {
          color: $white;
        }

        &::after {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border: solid var(--theme-color);
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          transition: transform $transition-speed $transition-timing;
        }
      }
    }

    &.active #{$comp}__heading::after {
      @include mq($until: L) {
        transform: rotate(45deg * 5);
      }
    }
  }

  &__link {
    @include menu-link;

    text-decoration: none;

    @include mq($until: L) {
      padding-top: $space / 2;
      padding-bottom: $space / 2;
      color: $footer-text-color;
      transition: color $transition-speed $transition-timing;

      &:hover,
      &:active {
        color: var(--theme-color);
      }
    }

    @include mq($from: L) {
      transform: skew(-$default-skew);
      padding-left: $space;
      padding-right: $space;

      &,
      &:hover,
      &:active {
        color: $footer-text-color;
      }

      &:hover,
      &:active {
        background: rgba($white, 0.05);
      }
    }
  }

  &__link-icon {
    @include menu-icon;
    fill: var(--theme-color);

    @include mq($until: L) {
      display: none;
    }
  }

  &__social {
    &--mobile {
      margin: 0 (-$space);
      padding: 22px $space;
      border-bottom: 1px solid $white;

      @include mq($from: L) {
        display: none;
      }
    }

    &--desktop {
      @include mq($until: L) {
        display: none;
      }
    }
  }

  &__copyright {
    font-size: $minFont;
    padding-top: $baseline;

    @include mq($from: L) {
      transform: skew(-$default-skew);
      padding: 27px 0;

      > *:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__smallprint {
    font-size: $minFont;

    a {
      transition: opacity $transition-speed $transition-timing;

      &:hover {
        opacity: 0.75;
      }
    }

    img {
      margin: 0 0 0 0.25rem;
      max-width: 220px;
    }

    @include mq($from: L) {
      transform: skew(-$default-skew);
      margin: 0;
    }
  }
}

@import "../variables";
@import "../mixins";
@import "../mq";

.showcase-list {
  &__filters {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: $baseline * 0.5;
    margin-bottom: $space;

    @include mq($from: M) {
      grid-gap: $baseline;
    }

    select {
      border-color: var(--theme-color, $border-color);

      &:focus {
        border-color: $color-default;
      }
    }
  }

  &__items {
    padding-bottom: 2.5rem;

    @include mq($from: M) {
      padding-bottom: $space;
    }

    @include mq($from: L) {
      padding-bottom: $space * 3;
    }
  }

  .grid {
    &__heading {
      margin-bottom: $baseline/4;

      a {
        transition: color $transition-speed $transition-timing;
      }
    }

    &__meta {
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 1.25;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    &__item {
      text-decoration: none;
    }

    &__description {
      margin-bottom: 0;
    }
  }
}

.showcase-modal {
  .bb-modal {
    &__dialog {
      background-color: $color-default;
      padding: 0;
    }

    &__titlebar {
      position: absolute;
      right: $space;
      top: $space;

      @include mq($from: L) {
        right: $space * 1.5;
      }
    }

    &__content {
      @include mq($from: M) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
      }
    }
  }

  &__content-container {
    display: none;
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin: 0;
    width: 100%;
  }

  &__content {
    font-size: 1rem;
    padding: $space;

    @include mq($from: S) {
      padding: $space * 2;
    }

    @include mq($from: L) {
      max-height: 800px;
      overflow: auto;
      padding-right: $space * 4;
    }

    &,
    h3 {
      color: $white;
    }

    h3 {
      font-family: $base-font;
      font-size: 1.5rem;
      font-style: normal;
      margin: 0 0 $baseline * 0.25;
      text-transform: none;
    }
  }

  &__heading {
    @include headingFont($h3-sm, $h3-lg);
    line-height: 1;
    margin: 0 0 $baseline/4;
    -webkit-text-stroke-width: 0;
  }

  &__meta {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  &__description {
    margin-bottom: 0;
  }

  &__categories {
    color: $color-default;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem;
    line-height: 1;
    list-style: none;
    margin-bottom: $baseline;

    li {
      background-color: var(--theme-color, $color-big-bang);
      margin-right: 4px;
      padding: 4px;
    }
  }

  &__share {
    .social-share-heading {
      display: inline-block;
      margin-bottom: $baseline * 0.25;
    }

    .social-share-list {
      margin: 0;

      &__icon {
        fill: var(--theme-color, $default-link-color);
      }

      a:hover .social-share-list__icon {
        fill: $white;
      }
    }
  }
}

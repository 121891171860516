@import "../../../node_modules/leaflet/dist/leaflet";
@import "../variables";

.map {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__main {
    height: 100%;
    position: relative;
    z-index: 1;
  }

  &__drawer {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #fff;
    transition: visibility 0.3s ease, opacity 0.3s ease, transform 0.3s ease;

    &[hidden] {
      display: block;
      visibility: hidden;
      opacity: 0;
      transform: translateY(100%);
    }

    @include mq($from: L) {
      width: 340px;
      left: auto;
      right: 30px;
      top: 30px;
      bottom: 30px;
      height: auto;

      &[hidden] {
        transform: translateX(100%);
      }
    }
  }

  &__drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    padding: 20px;

    @include mq($from: L) {
      padding: 30px;
    }
  }

  &__drawer-heading {
    @include headingFont($h4-sm, $h4-lg);
    margin: 0;
    -webkit-text-fill-color: $color-default;
    -webkit-text-stroke-color: transparent;
  }

  &__drawer-content {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
  }

  &__drawer-close {
    background: transparent;
    padding: 0;
    width: 26px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-color, $color-big-bang);
    border: 2px solid var(--theme-color, $color-big-bang);
    transform: skew($default-skew);
    flex: none;
    margin-left: 10px;

    &::before {
      content: "\2715";
      font-weight: bold;
      font-size: 16px;
      transition: transform 0.15s ease;
    }

    &:hover {
      background: none;

      &::before {
        transform: rotate(45deg * 4);
      }
    }

    &:focus {
      outline: none;
    }

    @include mq($from: XL) {
      width: 36px;
      height: 40px;

      &::before {
        font-size: 20px;
      }
    }
  }

  &__btn-back {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    text-decoration: none;
    padding: 10px 30px 10px 20px;
    font-size: 16px;

    &::before {
      content: "";
      transform: skew($default-skew);
      display: block;
      position: absolute;
      left: -10px;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background: $color-default;
    }

    &,
    &:hover {
      color: #fff;
    }

    .icon {
      color: $color-big-bang;
      width: 24px;
      height: 24px;
      flex: none;
      margin-right: 5px;
      transform: rotate(180deg);
      fill: currentColor;
    }
  }

  &__btn-drawer {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    background: transparent;
    border: 0;
    padding: 10px 20px;
    font-size: 16px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      transform: skew($default-skew);
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background: $color-big-bang;
      box-shadow: 3px 3px 0 0px $color-default;
      transition: transform 0.15s ease;
    }

    &,
    &:hover {
      color: #fff;
      background: transparent;
    }

    &:hover {
      &::before {
        transform: skew(0);
      }
    }

    &[aria-expanded="true"] {
      visibility: hidden;
    }

    .icon {
      color: #fff;
      width: 18px;
      height: 18px;
      flex: none;
      margin-right: 10px;
      fill: currentColor;
    }

    @include mq($from: L) {
      right: 30px;
      bottom: 30px;
    }
  }
}

.map-category-toggle {
  position: relative;
  background: transparent;
  border: 0;
  width: 100%;
  padding: 10px 20px 10px 50px;
  border-bottom: 1px solid $border-color;
  transition: background 0.15s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $heading-font;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.15;
  font-size: 16px;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
  }

  &::before {
    border: 1px solid $color-default;
  }

  &::after {
    background: url(/img/dist/sprite/tick.svg) 50% 50% / 12px no-repeat;
    opacity: 0;
    transition: opacity 0.15s ease;
  }

  &__icon {
    display: block;
    width: 20px;
    height: 20px;
    transform: skew($default-skew);
    margin-left: 10px;
    transition: border-color 0.15s ease, background-color 0.15s ease;
    flex: none;
    background-color: var(--theme-color, $color-big-bang);
  }

  &:hover,
  &:focus {
    background: $lightest-grey;
  }

  &:focus {
    outline: none;
  }

  &.is-active::after {
    opacity: 1;
  }

  @include mq($from: L) {
    padding-left: 60px;
    padding-right: 30px;

    &::before,
    &::after {
      left: 30px;
    }
  }
}

// Map markers

.marker {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  margin-left: -12px;

  &__icon {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    background-color: var(--theme-color, $color-big-bang);
    transform: skew($default-skew);
    box-shadow: 3px 3px 0 0px #fff;
    transition: transform 0.15s ease;
  }

  &:hover &__icon {
    transform: scale(1.2) skew($default-skew);
  }
}

// Leaflet overrides

.leaflet-container {
  background: $color-default;
}

.leaflet-control-zoom {
  transform: skew($default-skew);
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 20px;

  @include mq($from: L) {
    margin-bottom: 30px;
  }
}

.leaflet-left .leaflet-control {
  margin-left: 20px;

  @include mq($from: L) {
    margin-left: 30px;
  }
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: none;
  border-radius: 0;
  background: $color-default;
  box-shadow: 3px 3px 0 0px $color-default;
}

.leaflet-touch .leaflet-bar a:first-child,
.leaflet-touch .leaflet-bar a:last-child {
  border-radius: 0;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: $color-big-bang;
  border-bottom-color: $color-default;
  color: #fff;

  &:last-child {
    border: none;
  }
}

.leaflet-bar a.leaflet-disabled {
  opacity: 0.7;
  background: $color-big-bang;
  color: #fff;
}

@import "../../variables";
@import "../../mixins";
@import "../../mq";

.signpost-block {
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -$space;

    &--as-carousel {
      display: block;
      margin-bottom: 0;

      @include mq($from: XL) {
        margin-left: -$space/2;
        width: calc(100% + #{$space});
      }

      &::before {
        content: none;
      }

      .slick-slide > div {
        padding-left: $space/2;
        padding-right: $space/2;
      }
    }

    .content-panel__row--two-column & {
      display: block;
    }
  }

  &__item {
    background-color: $white;
    border: 1px solid $color-default;
    display: flex;
    flex-direction: column;
    margin-bottom: $space;
    width: 100%;
    text-decoration: none;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      z-index: -1;
      transition: width $transition-speed $transition-timing,
        height $transition-speed $transition-timing;
    }

    &:before {
      top: -1px;
      bottom: -1px;
      right: calc(100% + 1px);
      width: 0;
      transform: skewY(-45deg);
      background: $color-default;
      transform-origin: right top;
      will-change: width;
    }
    //Bottom
    &:after {
      left: -1px;
      right: -1px;
      top: calc(100% + 1px);
      height: 0;
      transform: skewX(-45deg);
      background: $color-default;
      transform-origin: left top;
      will-change: height;
    }

    .theme--competition .panel:not(.panel--dark) &,
    .theme--at-school .panel:not(.panel--dark) &,
    .theme--fair .panel:not(.panel--dark) &,
    .panel--theme-competition &,
    .panel--theme-at-school &,
    .panel--theme-fair &,
    .panel--dark & {
      &:before,
      &:after {
        background-color: $grey;
      }
    }

    .theme--competition .panel--inverted:not(.panel--dark) &,
    .theme--at-school .panel--inverted:not(.panel--dark) &,
    .theme--fair .panel--inverted:not(.panel--dark) &,
    .panel--theme-competition.panel--inverted &,
    .panel--theme-at-school.panel--inverted &,
    .panel--theme-fair.panel--inverted & {
      &:before,
      &:after {
        background-color: $white;
      }
    }

    &:hover,
    &:active {
      //Left
      &:before {
        width: 10px;
      }
      //Bottom
      &:after {
        height: 10px;
      }
    }

    @include mq($from: M) {
      margin-left: $space;
      width: calc(50% - #{$space/2});

      &:nth-child(odd) {
        margin-left: 0;
      }
    }

    @include mq($from: L) {
      width: calc(33% - 12px);

      &:nth-child(odd) {
        margin-left: $space;
      }

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }

    .signpost-block__items--as-carousel & {
      margin-bottom: $space / 2;
      width: auto;
    }
  }

  &__image-container {
    min-height: 0;

    @include mq($from: M) {
      height: 250px;
    }
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: 0.3s;
    width: 100%;
    &-fit {
      @include object-fit(contain, center);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $space * 1.5;

    .theme--competition .panel:not(.panel--dark) &,
    .theme--at-school .panel:not(.panel--dark) &,
    .theme--fair .panel:not(.panel--dark) &,
    .panel--theme-competition:not(.panel--dark) &,
    .panel--theme-at-school:not(.panel--dark) &,
    .panel--theme-fair:not(.panel--dark) & {
      background-color: $color-default;
    }
  }

  &__heading {
    @include headingFont($h4-sm, $h4-lg);
    color: var(--theme-color);
    letter-spacing: normal;
    line-height: 1.15;
    margin: 0 0 $space;
    -webkit-text-fill-color: currentColor;
    -webkit-text-stroke-width: 0;

    .panel--dark & {
      color: $color-default;
    }
  }

  &__description {
    font-size: $minFont;
    margin: 0;
    color: $default-text-color;

    .theme--competition .panel:not(.panel--dark) &,
    .theme--at-school .panel:not(.panel--dark) &,
    .theme--fair .panel:not(.panel--dark) &,
    .panel--theme-competition:not(.panel--dark) &,
    .panel--theme-at-school:not(.panel--dark) &,
    .panel--theme-fair:not(.panel--dark) & {
      color: $white;
    }
  }

  &__fake-cta {
    padding-left: 12px;
    padding-top: $baseline;

    @include mq($from: M) {
      display: none;
    }
  }
}
